import Vue from 'vue'
import Vuex from 'vuex'
import mutations from "../store/mutations";
import api from "../api";
import log from "../log";
import _ from "lodash";
import config from "../config";
Vue.use(Vuex)

const state = {
  ratingDialog : false,
  voted : localStorage.voted || false,
  numVotes : 0,
  average : 0,
  language : localStorage.language || 'en',
  drawer : false,
  initialized : false,
  privacyDialog : false,
  contractDialog : false,
  compareDialog : false,
  compareItems : [],
  loading : false,
  loadingMessage : 'Loading...',
  profile : null,
  dialogMessage : '',
  snackbar : false,
  snackbarColor : 'info',
  confirmDialog : false,
  confirmDialogTitle: 'Richiesta conferma',
  confirmDialogMessage : 'Sei sicuro?',
  confirmDialogCallback : null,
  searchTerm : '',
  mainCollection : config.collection,
  filterDialog : false,
  languages : [],
  artworks : [],
  artists : [],
  techniques : [],
  hotspots : [],
  categories : [],
  timeline: [],
  timelineTree: [],
  userLicenses: [],
  userOrganizations: [],
  collections: [],
  pendingHotspot : null,
  pendingHotspotRight : null,
  collection : null,
  localizations : {},
};

const getters = {
  collection: (state) => state.collection,
  collections: (state) => state.collections,
  voted: (state) => state.voted,
  numVotes: (state) => state.numVotes,
  average: (state) => state.average,
  ratingDialog: (state) => state.ratingDialog,
  initialized: (state) => state.initialized,
  language: (state) => state.language,
  measureUnit: (state) => {
    let filter = state.languages.filter((item) => item.code === state.language)
    if(!filter.length) return "cm";
    return filter[0].measure_unit || "cm";
  },
  measureRatio: (state) => {
    let filter = state.languages.filter((item) => item.code === state.language)
    if(!filter.length) return 1;
    return filter[0].measure_ratio || 1;
  },
  languageMap: (state) => state.localizations[state.language] || state.localizations.en || state.localizations.it || {},
  token : (state) => state.token,
  profile : (state) => state.profile,
  languages : (state) => state.languages,
  mainCollection : (state) => state.mainCollection,
  artworks : (state) => state.artworks,
  artists : (state) => state.artists,
  techniques : (state) => state.techniques,
  categories : (state) => state.categories,
  hotspots : (state) => state.hotspots,
  pendingHotspot : (state) => state.pendingHotspot,
  pendingHotspotRight : (state) => state.pendingHotspotRight,
  //categoriesMap : () => _.keyBy(state.categories, '_id'),
  timeline : (state) => state.timeline,
  timelineTree : (state) => state.timelineTree,
  userLicenses : (state) => state.userLicenses,
}

const actions = {
  login : async ({commit}) => {
    let { token } = await api.login();
    log.d("login success", token);
    commit('token', { token :token });
  },
  sendRating : async ({commit},{vote}) => {
    try {
      await api.vote({
        "user" : '',
        "target" : 'mantegna',
        vote,
      });
    } catch (e) {
      log.e("voting error", e)
    }
  },
  getRating : async ({commit}) => {
    try {
      let res = await api.getAverageVotes();
      if(res.num_votes) {
        commit('numVotes', { value : res.num_votes });
        commit('average', { value : res.average });
      }
    } catch (e) {
      log.e("voting error", e)
    }
  },
  setInitialized({commit}) {
    commit('initialized',  { value : true })
  },
  setLanguage : ({commit}, { language }) => {
    commit('language',  { language })
  },
  async refreshToken({ commit }) {
    let reply = await api.heartbeat();
    const token = sessionStorage.token;
    if(!token) {
      throw { status : 401, message : "sessione scaduta"}
    }
    commit('token', { token : reply.data.authToken });
    commit('profile', { profile : reply.data.user });
  },
  sendSuccess({ commit }, { message }) {
    commit('message', { type : 'success', message })
  },
  sendWarning({ commit }, { message }) {
    commit('message', { type : 'warning', message })
  },
  sendError({ commit }, { message }) {
    commit('message', { type : 'error', message })
  },
  sendInfo({ commit }, { message }) {
    commit('message', { type : 'info', message })
  },
  load({commit}, { collection, filter }) {
    let { query, limit, page, sort  } = filter;
    return api.restLoad( collection, query, limit, page, sort );
  },
  get({commit}, { collection, id }) {
    return api.restGet( collection, id);
  },
  insert({commit}, { collection, data }) {
    return api.restPost( collection, data );
  },
  update({commit}, { collection, data }) {
    return api.restPut( collection, data );
  },
  remove({commit}, { collection, id }) {
    return api.restDelete( collection, id );
  },
  async loadUserLicenses({commit}) {

    try {
      let reply = await api.getUserLicenses()
      commit('userLicenses', { licenses : reply.data });
      //commit('userOrganizations', { organizations : reply.organizations });
    } catch (e) {
      log.w(e)
    }
  },
  async isArtworkVTLicensed({ commit }, { artwork }) {
    let allLangs = state.languages;
    let languages = [];
    let isLicensed = false;
    let allLicensed = false;

    for (let i = 0; i < state.userLicenses.length; i++) {
      const lic = state.userLicenses[i];
      if(!lic.virtual_tours) {
        continue;
      }
      if(!lic.artworks.enabled || (!lic.artworks.all && !lic.artworks.list.contains(artwork._id))) {
        continue;
      }
      isLicensed = true;

      let langFilter = [];
      if(lic.languages.enabled && lic.languages.all) {
        langFilter = allLangs.map((item) => { return item.code });
      }
      if(lic.languages.enabled && lic.languages.list.length) {
        langFilter = allLangs.filter((item)=>{lic.languages.list.includes(item._id)}).map((item) => { return item.code });
      }
      languages.push(...langFilter);
    }
    languages = _.uniq(languages)
    return { isLicensed, allLicensed, languages }
  },
  async loadCollection({commit}) {
    log.d("loadCollection()")
    let { data } = await api.restGet("collections", config.collection)
    log.d("loadTimeline() done")
    commit('collection', { data });
  },
  async loadTimeline({ commit }) {
    log.d("loadTimeline()")
    if(state.timelineTree && state.timelineTree.length) {
      return;
    }
    let { data } = await api.restGet("timeline", "tree")
    log.d("loadTimeline() done")
    commit('timelineTree', { data });
  },
  async loadTimelineArtworksOf({ commit },{ id, collection }) {
    log.d("loadTimelineArtworksOf()")
    let path = id + "/artworks";
    if(collection) {
      path += '?collection=' + collection
    }
    return await api.restGet("timeline", path)
  },
  refreshCollection({ commit }, { collection }) {
    log.d("refreshCollection()", collection)
    if(state[collection] && state[collection].length) return;

    api.restLoad( collection, {}, 0, 1, null).then(( reply )=>{
      log.d("refreshCollection() done", collection)
      if(reply.data) {
        commit(collection, { data : reply.data });
      }
    }).catch((err) => { log.w("refreshCollection()", collection, err) })
  },
  async refreshCollectionPromise({ commit }, { collection }) {
    log.d("refreshCollectionPromise()", collection)
    let { data } = await api.restLoad(  collection, {}, 0, 1, null)
    commit(collection, { data });
  },
  async refreshLocalizations({ commit }) {
    let { data } = await api.restGet("app", "localizations")
    commit('localizations', { data });
  },
  setPendingHotspot({commit}, { hotspot }) {
    commit('pendingHotspot', { hotspot })
  },
  setPendingHotspotRight({commit}, { hotspot }) {
    commit('pendingHotspotRight', { hotspot })
  },
  requestConfirm({commit}, { title, message, callback }) {
    commit('confirm', { title, message, callback })
  },
  async getSuggestions({commit},{ data, collection }) {
    let url = 'hotspots/suggestions'
    if(collection) {
      url += '?collection='+collection;
    }
    return await api.restPost(url, data);
  },
  compare({commit}, { item, hotspot }) {
    if(hotspot) {
      item.hotspot = hotspot;
    }
    if(state.compareItems.length && state.compareItems[0]._id === item._id) {
      state.compareItems.shift();
      return;
    }

    if(state.compareItems.length === 2) {
      return
    }
    state.compareItems.push(item);
    if(state.compareItems.length === 2) {
      state.compareDialog = true;
    }
  }
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
})
