const LEVEL = "prod";

const commons = {
    defaultLanguage : 'it',
    siblingsRatio : 4,
    restrictRatio : {
        x : 0.1,
        y : 0.1
    },
    capSmallTextSize : 28,
    collection : '61fbb6049cb051e0aed9ff42',
    artist : 'qTprbQF3viPvJWnLr'
};

const dev = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    server : 'http://localhost:8000',
    apiKey: 'RMH26DWTjQ9Jm'
};
const devlan = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    server : 'http://192.168.1.42:8000',
    apiKey: 'RMH26DWTjQ9Jm'
};
const preprod = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    server : 'https://api.art.centrica.it',
    apiKey: 'RMH26DWTjQ9Jm'
};

const prod = {
    debug : false,
    logEnabled : false,
    logLevel : 'error', // debug, info, warning, error
    server : 'https://api.art.centrica.it',
    apiKey: 'RMH26DWTjQ9Jm'
};

let config;

switch (LEVEL) {
    case "prod":
        config = Object.assign(commons, prod);
        break;
    case "preprod":
        config = Object.assign(commons, preprod);
        break;
    case "devlan":
        config = Object.assign(commons, devlan);
        break
    case "dev":
    default:
        config = Object.assign(commons, dev);
        break;
}

export default config;