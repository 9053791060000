import defaults from "../defaults";
class ImageJob {
    timeout = defaults.timeout;
    jobId = null;
    /**
     * Image object which will contain downloaded image.
     * @member {HTMLImageElement} image
     * @memberof XLviewer.ImageJob#
     */
    image = null;
    errorMsg = null;
    enableCORS = false;
    src;
    callback;
    constructor(options) {
        Object.assign(this, options);
    }

    start(){
        this.image = new Image();

        if ( this.enableCORS !== false ) {
            this.image.crossOrigin = this.enableCORS;
        }

        this.image.onload = () => {
            this.finish( true );
        };
        this.image.onabort = this.image.onerror = () => {
            this.errorMsg = "Image load aborted";
            this.finish( false );
        };

        this.jobId = window.setTimeout( () => {
            this.errorMsg = "Image load exceeded timeout";
            this.finish( false );
        }, this.timeout);

        this.image.src = this.src;
    }

    finish( successful ) {
        this.image.onload = this.image.onerror = this.image.onabort = null;
        if (!successful) {
            this.image = null;
        }

        if ( this.jobId ) {
            window.clearTimeout( this.jobId );
        }

        this.callback( this );
    }
}

export default ImageJob