<template>
  <v-container class="full-width fill-height black pa-0 ma-0" style="max-width: 100%">
    <v-row class="fill-height pa-0 ma-0">
      <v-col cols="12" lg="6" :class="`${viewerHeightClass} pa-0 ma-0 relative`" :style="showRightInfo ? 'opacity: 10%' : ''">
        <xl-viewer id="xlviewerLeft" ref="xlviewerLeft" :server="imgfLeftParams.path" v-model="imgfLeftParams.name" force-https
                   @viewportChange="onLeftViewportChange" @open="onLeftImageOpen" class="full-width fill-height"/>


        <div class="absolute bottom d-flex justify-center align-center full-width" style="z-index: 500; bottom: 8px">
          <v-spacer></v-spacer>
          <v-tooltip v-model="tooltips.zoominLeft" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="$refs.xlviewerLeft.zoomIn()"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
            </template>
            <span>{{L('zoom in')}}</span>
          </v-tooltip>
          <v-tooltip v-model="tooltips.zoomoutLeft" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="$refs.xlviewerLeft.zoomOut()"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
            </template>
            <span>{{L('zoom out')}}</span>
          </v-tooltip>
          <v-tooltip v-model="tooltips.resetLeft" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="$refs.xlviewerLeft.reset()"><v-icon>mdi-autorenew</v-icon></v-btn>
            </template>
            <span>{{L('reset view')}}</span>
          </v-tooltip>
          <v-tooltip v-model="tooltips.infoLeft" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-6 mr-3" :color="showLeftInfo ? 'accent' : ''" icon @click="setOpenInfo('left', true);"><v-icon>mdi-information-variant</v-icon></v-btn>
            </template>
            <span>{{L('info')}}</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="12" lg="6" :class="`${viewerHeightClass} pa-0 ma-0 relative`" :style="showLeftInfo ? 'opacity: 10%' : ''">
        <xl-viewer id="xlviewerRight" ref="xlviewerRight" :server="imgfRightParams.path" v-model="imgfRightParams.name" force-https
                   @viewportChange="onRightViewportChange" @open="onRightImageOpen" class="full-width fill-height"/>

        <div class="absolute bottom d-flex justify-center align-center full-width" style="z-index: 500; bottom: 8px">
          <v-spacer></v-spacer>
          <v-tooltip v-model="tooltips.zoominRight" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="$refs.xlviewerRight.zoomIn()"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
            </template>
            <span>{{L('zoom in')}}</span>
          </v-tooltip>
          <v-tooltip v-model="tooltips.zoomoutRight" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="$refs.xlviewerRight.zoomOut()"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
            </template>
            <span>{{L('zoom out')}}</span>
          </v-tooltip>
          <v-tooltip v-model="tooltips.resetRight" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="$refs.xlviewerRight.reset()"><v-icon>mdi-autorenew</v-icon></v-btn>
            </template>
            <span>{{L('reset view')}}</span>
          </v-tooltip>
          <v-tooltip v-model="tooltips.infoRight" top color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-6 mr-3" :color="showRightInfo ? 'accent' : ''"  icon @click="setOpenInfo('right', true);"><v-icon>mdi-information-variant</v-icon></v-btn>
            </template>
            <span>{{L('info')}}</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>


    <div v-if="artworkLeft && showLeftTitles" :class="'backdrop-blur artwork-name-left' + (vertical ? ' vertical' : '') + ' ' + $vuetify.breakpoint.name">
      <p :class="artistNameClass" >{{artistsNamesLeft}}</p>
      <p :class="titleClass">{{titleLeft}}</p>
    </div>

    <div v-if="artworkRight && showRightTitles" :class="'backdrop-blur artwork-name-right' + (vertical ? ' vertical' : '') + ' ' + $vuetify.breakpoint.name">
      <p :class="artistNameClass" >{{artistsNamesRight}}</p>
      <p :class="titleClass">{{titleRight}}</p>
    </div>

    <div class="d-flex flex-row ma-3" style="position: absolute; top: 0; left :0;">
      <v-btn icon class="backdrop-blur" @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn>
      <v-btn icon class="backdrop-blur ml-2" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
    </div>

    <v-dialog v-model="showLeftInfo" width="80%" style="z-index: 501">
      <v-card class="backdrop-blur">
        <v-container class="pa-6">
          <v-row>
            <v-col cols="12" v-if="artworkLeft">
              <p class="display-1 mb-0 font-weight-regular">{{titleLeft}}</p>
              <p class="headline mb-0 font-weight-regular accent--text">{{artistsNamesLeft}}</p>
              <p class="title mb-5 font-weight-medium" v-if="datingLeft">{{datingLeft}}</p>
              <p class="title mb-0 font-weight-medium font-italic" v-if="techniqueLeft">{{techniqueLeft}}</p>
              <p class="title mb-0 font-weight-medium" v-if="roomLeft">{{roomLeft}}</p>
              <p class="title mb-5 font-weight-medium" v-if="sizeLeft">{{sizeLeft}}</p>
              <p class="body-1 mb-5 font-weight-regular pre">{{descriptionLeft}}</p>
              <p class="body-1 mb-0 font-weight-regular" v-if="artworkLeft.signature"><span class="grey--text">{{L('Signature')}}:</span> {{artworkLeft.signature}}</p>
              <p class="body-1 mb-0 font-weight-regular grey--text" v-if="artworkLeft.attachments && artworkLeft.attachments.length">{{L('Other contents')}}:</p>
              <ul v-if="artworkLeft.attachments && artworkLeft.attachments.length">
                <li v-for="(att, i) in artworkLeft.attachments" :key="`${i}_${att.url}`"><a :href="att.url">{{att.title}}</a></li>
              </ul>
            </v-col>
          </v-row>

          <v-btn icon absolute top right @click="setOpenInfo('left', false);"><v-icon>mdi-close</v-icon></v-btn>
        </v-container>
      </v-card>
    </v-dialog>


    <v-dialog v-model="showRightInfo" width="80%" style="z-index: 501">
      <v-card class="backdrop-blur">
        <v-container class="pa-6">
          <v-row>
            <v-col cols="12" v-if="artworkRight">
              <p class="display-1 mb-0 font-weight-regular">{{titleRight}}</p>
              <p class="headline mb-0 font-weight-regular accent--text">{{artistsNamesRight}}</p>
              <p class="title mb-5 font-weight-medium" v-if="datingRight">{{datingRight}}</p>
              <p class="title mb-0 font-weight-medium font-italic" v-if="techniqueRight">{{techniqueRight}}</p>
              <p class="title mb-0 font-weight-medium" v-if="roomRight">{{roomRight}}</p>
              <p class="title mb-5 font-weight-medium" v-if="sizeRight">{{sizeRight}}</p>
              <p class="body-1 mb-5 font-weight-regular pre">{{descriptionRight}}</p>
              <p class="body-1 mb-0 font-weight-regular" v-if="artworkRight.signature"><span class="grey--text">{{L('Signature')}}:</span> {{artworkRight.signature}}</p>
              <p class="body-1 mb-0 font-weight-regular grey--text" v-if="artworkRight.attachments && artworkRight.attachments.length">{{L('Other contents')}}:</p>
              <ul v-if="artworkRight.attachments && artworkRight.attachments.length">
                <li v-for="(att, i) in artworkRight.attachments" :key="`${i}_${att.url}`"><a :href="att.url">{{att.title}}</a></li>
              </ul>
            </v-col>
          </v-row>

          <v-btn icon absolute top right @click="setOpenInfo('right', false);"><v-icon>mdi-close</v-icon></v-btn>
        </v-container>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import config from '../config'
import utils from '../utils'
import log from '../log'
import { mapActions, mapGetters } from 'vuex'
import XLviewer from "../components/XLviewer";
export default {
  components : {
    "xl-viewer" : XLviewer
  },
  name: 'Compare',
  data() {
    return {
      tooltips : {
        zoominLeft : false,
        zoomoutLeft : false,
        resetLeft : false,
        infoLeft : false,
        zoominRight : false,
        zoomoutRight : false,
        resetRight : false,
        infoRight : false,
      },
      sendBoundsTimeout : null,
      scrolling : false,
      bounds : null,
      showLeftInfo : false,
      showRightInfo : false,
      showLeftTitles: true,
      showRightTitles: true,
      imgfLeftParams : {
        path : null,
        name : null
      },
      imgfRightParams : {
        path : null,
        name : null
      },
      artworkLeft : null,
      artworkRight : null,
    }
  },
  computed : {
    ...mapGetters(['artists','pendingHotspot','pendingHotspotRight','language','languageMap']),
    artistNameClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 'ml-2 body-1 mb-0 regular accent--text text-right'
        case "md":
          return 'ml-2 body-1 mb-0 regular accent--text'
        case "lg":
          return 'ml-2 title mb-0 regular accent--text'
      }
      return 'ml-2 headline mb-0 regular accent--text'
    },
    titleClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 'ml-2 title mb-0 regular text-right'
        case "md":
          return 'ml-2 title mb-0 regular'
        case "lg":
          return 'ml-2 headline mb-0 regular'
      }
      return 'ml-2 display-1 mb-0 regular'
    },
    vertical() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        case 'lg': return false
        case 'xl': return false
      }
      return false
    },
    viewerHeightClass () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'mid-height'
        case 'sm': return 'mid-height'
        case 'md': return 'mid-height'
        case 'lg': return 'fill-height'
        case 'xl': return 'fill-height'
      }
      return "fill-height"
    },
    drawer : {
      get() { return this.$store.state.drawer; },
      set(newVal) { this.$store.state.drawer = newVal; }
    },
    titleLeft() {
      const lng = this.language;
      return this.artworkLeft.title[lng] || this.artworkLeft.title.it || this.artwork.title.en || '<no translation>';
    },
    titleRight() {
      const lng = this.language;
      return this.artworkRight.title[lng] || this.artworkRight.title.it || this.artworkRight.title.en || '<no translation>';
    },
    datingLeft() {
      if(!this.artworkLeft.date_display) return '';
      const lng = this.language;
      return this.artworkLeft.date_display[lng] || this.artworkLeft.date_display.it || this.artworkLeft.date_display.en || '';
    },
    datingRight() {
      if(!this.artworkRight.date_display) return '';
      const lng = this.language;
      return this.artworkRight.date_display[lng] || this.artworkRight.date_display.it || this.artworkRight.date_display.en || '';
    },
    roomLeft() {
      if(!this.artworkLeft.room_name) return '';
      const lng = this.language;
      return this.artworkLeft.room_name[lng] || this.artworkLeft.room_name.it || this.artworkLeft.room_name.en || '';
    },
    roomRight() {
      if(!this.artworkRight.room_name) return '';
      const lng = this.language;
      return this.artworkRight.room_name[lng] || this.artworkRight.room_name.it || this.artworkRight.room_name.en || '';
    },
    sizeLeft() {
      if(!this.artworkLeft.size_display) return '';
      const lng = this.language;
      return this.artworkLeft.size_display[lng] || this.artworkLeft.size_display.it || this.artworkLeft.size_display.en || '';
    },
    sizeRight() {
      if(!this.artworkRight.size_display) return '';
      const lng = this.language;
      return this.artworkRight.size_display[lng] || this.artworkRight.size_display.it || this.artworkRight.size_display.en || '';
    },
    techniqueLeft() {
      if(!this.artworkLeft.technique) return '';
      const lng = this.language;
      return this.artworkLeft.technique[lng] || this.artworkLeft.technique.it || this.artworkLeft.technique.en || '';
    },
    techniqueRight() {
      if(!this.artworkRight.technique) return '';
      const lng = this.language;
      return this.artworkRight.technique[lng] || this.artworkRight.technique.it || this.artworkRight.technique.en || '';
    },
    descriptionLeft() {
      const lng = this.language;
      return this.artworkLeft.desc[lng] || this.artworkLeft.desc.it || this.artworkLeft.desc.en || '<no translation>';
    },
    descriptionRight() {
      const lng = this.language;
      return this.artworkRight.desc[lng] || this.artworkRight.desc.it || this.artworkRight.desc.en || '<no translation>';
    },
    artistsNamesLeft() {
      return this.getArtists(this.artworkLeft)
    },
    artistsNamesRight() {
      return this.getArtists(this.artworkRight)
    },
  },
  methods: {
    ...mapActions(['load','get','sendError','refreshCollection','setPendingHotspot','setPendingHotspotRight']),
    L( key ) {
      return this.languageMap[key] || key;
    },
    setOpenInfo( where, value ) {
      if(where === 'left') {
        this.showLeftInfo = value
      } else {
        this.showRightInfo = value;
      }
    },
    getTitle(item) {
      if(!item) return '';
      const lng = this.language;
      const title = item.title[lng] ? item.title[lng] : item.title.it ? item.title.it : item.title.en ? item.title.en : '<no translation>';
      return utils.capText(title, 26);
    },
    getArtists(item) {
      if(!item) return '';
      if(!item.artists_names || !item.artists_names.length) {
        return this.L('unknown artist');
      }
      const lng = this.language
      return item.artists_names.map((names) => {
        return names[lng] ? names[lng] : names.it ? names.it : names.en ? names.en : '<no translation>'
      }).join(", ")
    },
    getArtistsNames(ids) {
      let artistName = "";
      for(let i = 0; i < ids.length; i++) {
        let id = ids[i];
        let artist = this.artists.filter((item) => item._id === id).shift();
        if(!artist) continue;
        if(artist.name[this.language]) {
          if(artistName.length > 0) {
            artistName += ", ";
          }
          artistName += artist.name[this.language]
        }
      }
      return artistName;
    },

    onLeftImageOpen() {
      if(this.pendingHotspot) {
        setTimeout(() => {
          let hsp = this.pendingHotspot;
          this.$refs.xlviewerLeft.zoomToBounds(hsp.x, hsp.y, hsp.w, hsp.h, false, true);
          this.setPendingHotspot({hotspot: null});
        }, 1000);
      }
    },
    onRightImageOpen() {
      if(this.pendingHotspotRight) {
        setTimeout(() => {
          let hsp = this.pendingHotspotRight;
          this.$refs.xlviewerRight.zoomToBounds(hsp.x, hsp.y, hsp.w, hsp.h, false, true);
          this.setPendingHotspotRight({hotspot: null});
        }, 1000);
      }
    },

    onLeftViewportChange() {
      let bounds = this.$refs.xlviewerLeft.getBounds(true);
      this.showLeftTitles = !(bounds.width < 0.98 || bounds.height < 0.98);
    },
    onRightViewportChange() {
      let bounds = this.$refs.xlviewerRight.getBounds(true);
      this.showRightTitles = !(bounds.width < 0.98 || bounds.height < 0.98);
    },

    loadLeftData() {
      let spl = this.$route.params.id.split('-');
      let id = spl[0] + '?extras=true';
      this.get({collection : 'artworks', id}).then((reply)=>{
        if(reply.success) {
          this.artworkLeft = reply.data;
          let imgfUrl = this.artworkLeft.imgfUrl;
          this.imgfLeftParams = utils.splitUrl(imgfUrl);
          return;
        } else {
          this.imgfLeftParams = utils.splitUrl();
        }
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
      }).catch((err)=>{
        if(!utils.checkError(this, err)) return;
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
      })
    },
    loadRightData() {
      let spl = this.$route.params.id.split('-');
      if(spl.length !== 2) {
        log.w("invalid param id in compare path")
        this.$router.go(-1);
        return;
      }
      let id = spl[1] + '?extras=true';
      this.get({collection : 'artworks', id}).then((reply)=>{
        if(reply.success) {
          this.artworkRight = reply.data;
          let imgfUrl = this.artworkRight.imgfUrl;
          this.imgfRightParams = utils.splitUrl(imgfUrl);
          return;
        } else {
          this.imgfRightParams = utils.splitUrl();
        }
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
      }).catch((err)=>{
        if(!utils.checkError(this, err)) return;
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
      })
    },
  },
  mounted() {
    this.refreshCollection({collection: 'artists'});
    this.showLeftTitles = true;
    this.showRightTitles = true;
    this.drawer = false;
    setTimeout(this.loadLeftData, 500);
    setTimeout(this.loadRightData, 500);
  },
}
</script>

<style scoped>
.artwork-name-left {
  position: fixed;
  z-index: 10;
  bottom : 0;
  left : 0;
  padding: 16px;
  background: rgba(0,0,0, .6);
  max-width: 39%;
}
.artwork-name-right {
  position: fixed;
  z-index: 10;
  bottom : 0;
  left : 50%;
  padding: 16px;
  background: rgba(0,0,0, .6);
  max-width: 39%;
}

.artwork-name-left.vertical {
  bottom : 50%;
  left : 0;
}


.artwork-name-right.vertical {
  bottom : 0;
  left : 0;
}

.artwork-name-left.xs, .artwork-name-left.sm, .artwork-name-right.xs, .artwork-name-right.sm {
  bottom: 50px;
  right: 0;
  left: auto;
  max-width: 100%;
}

.artwork-name-left.vertical.xs, .artwork-name-left.vertical.sm {
  bottom : calc(50% + 50px);
}

.artwork-name-left.md, .artwork-name-right.md {
  max-width: 60%;
}
.artwork-name-left.lg, .artwork-name-right.lg  {
  max-width: 34%;
}

</style>