import defaults from "../defaults";
import ImageJob from './ImageJob'
class ImageLoader {
    jobLimit = defaults.imageLoaderLimit;
    jobQueue = [];
    jobsInProgress = 0;
    /**
     * @ignore
     * @class ImageLoader
     * @memberof XLviewer
     * @classdesc Handles downloading of a set of images using asynchronous queue pattern.
     * You generally won't have to interact with the ImageLoader directly.
     * @param {Object} options - Options for this ImageLoader.
     * @param {Number} [options.jobLimit] - The number of concurrent image requests. See imageLoaderLimit in {@link XLviewer.Options} for details.
     */
    constructor(options) {
        Object.assign(this, options)
    }

    /**
     * Add an unloaded image to the loader queue.
     * @method
     * @param {Object} options
     * @param [options.src]{String} URL of image to download.
     * @param [options.enableCORS]{String} CORS policy to use for downloads
     * @param [options.callback]{Function} Called once image has been downloaded.
     * @param [options.abort]
     */
    addJob( options ) {
        let complete = ( job ) => {
                this.#completeJob( job, options.callback );
            },
            jobOptions = {
                src: options.src,
                enableCORS: options.enableCORS,
                callback: complete,
                abort: options.abort
            },
            newJob = new ImageJob( jobOptions );

        if ( !this.jobLimit || this.jobsInProgress < this.jobLimit ) {
            newJob.start();
            this.jobsInProgress++;
        }
        else {
            this.jobQueue.push( newJob );
        }
    }

    /**
     * Clear any unstarted image loading jobs from the queue.
     * @method
     */
    clear() {
        let i, len = this.jobQueue.length;
        for( i = 0; i < len; i++ ) {
            const job = this.jobQueue[i];
            if ( typeof job.abort === "function" ) {
                job.abort();
            }
        }
        this.jobQueue = [];
    }

    /**
     * Cleans up ImageJob once completed.
     * @method
     * @private
     * @param job - The ImageJob that has completed.
     * @param callback - Called once cleanup is finished.
     */
    #completeJob( job, callback ) {
        let nextJob;
        this.jobsInProgress--;
        if ( (!this.jobLimit || this.jobsInProgress < this.jobLimit) && this.jobQueue.length > 0) {
            nextJob = this.jobQueue.shift();
            nextJob.start();
            this.jobsInProgress++;
        }
        callback( job.image, job.errorMsg );
    }
}
export default ImageLoader;