<template>
  <div class="xlv-wrap" >
    <div :id="id" :class="'xlviewer ' + className"></div>
  </div>

</template>

<script>
import _ from 'lodash'
import XLviewer from '../xlviewer'
import utils from '../utils'
import log from '../log'
import {Point, Rect} from "../xlviewer";
export default {
  name: "XlViewer",
  data() {
    return {
      model : this.value,
      viewer : null,
      toolbar : null,
      seed : 0,
    }
  },
  props: {
    id : {
      type: String
    },
    value : {
      type: String
    },
    server : {
      type: String
    },
    quality : {
      type: Number,
      default: 90
    },
    className: {
      type: String,
      default : ''
    },
    forceHttps : {
      type :Boolean,
      default : false
    }
  },
  watch : {
    value() {
      this.model = this.value;
      log.d("change viewer model")
      this.load();
    },
  },
  computed : {
    serverUrl() {
      if(!this.server) return '';
      let url = this.server;
      if(this.forceHttps && url.indexOf('https://') === -1) {
        url = url.replace('http://', 'https://')
      }
      return url;
    }
  },
  methods: {
    zoomIn() {
      this.viewer.zoomIn()
    },
    zoomOut() {
      this.viewer.zoomOut()
    },
    reset() {
      this.viewer.zoomReset()
    },
    getPoint( clientX, clientY ) {
      return this.viewer.sceneToLocal( new Point(clientX,clientY), true, true )
    },
    getLocalPoint( boundsX, boundsY ) {
      return this.viewer.localToScene( new Point(boundsX,boundsY), true )
    },
    getBounds( current ) {
      return this.viewer.getBounds( current )
    },
    measure( a, b, w, h ) {
      let pointA = this.viewer.sceneToLocal( new Point(a.x,a.y), true, true )
      pointA.x *= w;
      pointA.y *= h;
      let pointB = this.viewer.sceneToLocal( new Point(b.x,b.y), true, true )
      pointB.x *= w;
      pointB.y *= h;
      return utils.calculateDistanceBetweenTwoPoints(pointA, pointB)
    },
    getZoomPercent() {
      if(!this.viewer) return 0;
      let min = this.viewer.viewport.getMinZoom();
      let max = this.viewer.viewport.getMaxZoom();
      let current = this.viewer.viewport.getZoom(true)
      return Math.round(_.clamp(100 / (max - min)  * (current - min), 0 , 100))
    },
    zoomToBounds(x, y, w, h, immediately, applyConstraint) {
      this.viewer.zoomToBounds( new Rect(x,y,w,h), immediately, applyConstraint);
    },
    _build() {
      if(!this.model)  {
        return;
      }
      this.viewer = new XLviewer({
        // debug: true,
        id: this.id,
        quality: this.quality,
        useNavigator : true,
        source : this.serverUrl,
        image : this.model
      });
      this.viewer.addListener('viewportChange', this.onViewportChange)
      this.viewer.addListener('open', this.onImageOpen)
    },
    load() {
      if(this.viewer) {
        this.viewer.destroy();
      }
      this._build();
    },
    forceRedraw() {
      this.seed++
    },
    onViewportChange() {
      //console.log(this.viewer.getBounds( true ))
      this.$emit('viewportChange')
    },
    onImageOpen() {
      this.$emit('open');
    }
  },
  mounted() {
    if(!this.value) return;
    this.load();
  },
  beforeDestroy() {
    this.viewer && this.viewer.destroy();
  }
}
</script>


