import axios from 'axios'
import log from '../log';
import config from "../config";
import utils from "../utils";

const baseUrl = config.server;

const handleError = (error) => {
    if (error.response) {
        // Request made and server responded
        log.i(error.response.data);
        log.i(error.response.status);
        log.i(error.response.headers);
        const message = error.response.data ? error.response.data.message : error.response.data
        return  { status : error.response.status, message }
    } else if (error.request) {
        // The request was made but no response was received
        log.i(error.request);
        return { status : 500, message : 'errore di connessione al server'}
    } else {
        // Something happened in setting up the request that triggered an Error
        log.i(error.message)
        return { status : 500, message : 'errore di connessione al server'}
    }
}

export default {

    async login() {
        try {
            const token = config.apiKey;
            let res = await axios.post(`${baseUrl}/api/alogin`, { token });
            log.d(res);
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },
    async vote( data ) {
        try {
            const token = sessionStorage.token;
            let res = await axios.post(`${baseUrl}/api/votes`, data, { headers: {'x-auth-token': token} });
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },
    async getAverageVotes() {
        try {
            const token = sessionStorage.token;
            let res = await axios.get(`${baseUrl}/api/votes/mantegna`, { headers: {'x-auth-token': token} });
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },
    async heartbeat() {
        const token = sessionStorage.token
        if(!token) throw { status : 401, message : 'non hai i permessi per effettuare questa richiesta'}
        try {
            let res = await axios.get(`${baseUrl}/api/heartbeat`, { headers: {'x-auth-token': token} });
            log.d(res);
            return res.data;
        } catch (e) {
            throw handleError(e)
        }
    },
    async getUserLicenses() {
        const token = sessionStorage.token
        if(!token) throw { status : 401, message : 'non hai i permessi per effettuare questa richiesta'}
        try {
            let res = await axios.get(`${baseUrl}/api/configuration`, { headers: {'x-auth-token': token} });
            log.d(res);
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },
    async restLoad( collection, query, limit, page, sort ) {
        let url = `${baseUrl}/api/${collection}?limit=${limit}&page=${page-1}`;
        for(const k in query) {
            if(query.hasOwnProperty(k) && query[k] !== undefined && query[k] !== null)
                url += "&"+k+"="+query[k];
        }
        if(sort) {
            url += "&sort="+sort;
        }
        url = utils.encodeUrl(url);
        const token = sessionStorage.token
        const config = token ? { headers: {'x-auth-token': token} } : {};
        try {
            let res = await axios.get(url, config);
            log.d(res);
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },
    async restGet( collection, id ) {
        let url = `${baseUrl}/api/${collection}/${id}`;
        url = utils.encodeUrl(url);
        const token = sessionStorage.token
        const config = token ? { headers: {'x-auth-token': token} } : {};
        try {
            let res = await axios.get(url, config);
            log.d(res);
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },

    async restPost( collection, data ) {
        let url = `${baseUrl}/api/${collection}`;
        url = utils.encodeUrl(url);
        const token = sessionStorage.token
        const config = token ? { headers: {'x-auth-token': token} } : {};
        try {
            let res = await axios.post(url, data, config);
            log.d(res);
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },
    async restPut( collection, data ) {
        let url = `${baseUrl}/api/${collection}/${data._id}`;
        const token = sessionStorage.token
        const config = token ? { headers: {'x-auth-token': token} } : {};
        try {
            let res = await axios.put(url, data, config);
            log.d(res);
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    },
    async restDelete( collection, id ) {
        let url = `${baseUrl}/api/${collection}/${id}`;
        const token = sessionStorage.token
        const config = token ? { headers: {'x-auth-token': token} } : {};
        try {
            let res = await axios.delete(url, config);
            log.d(res);
            return res.data
        } catch (e) {
            throw handleError(e)
        }
    }
}