<template>
<div class="d-flex justify-center align-center full-width fill-height">
  <v-progress-circular indeterminate size="60"></v-progress-circular>
</div>
</template>

<script>
export default {
name: "Loading"
}
</script>

<style scoped>

</style>