<template>
  <v-container ref="wrap" class="full-width fill-height black pa-0 ma-0" >
    <xl-viewer id="xlviewer" ref="xlviewer" :server="imgfParams.path" v-model="imgfParams.name" force-https
               @viewportChange="onViewportChange" @open="onImageOpen" class="full-width fill-height"
               style="position: absolute; top: 0; left: 0;"/>


    <div v-if="artwork && showTitles" :class="'backdrop-blur artworkName ' + $vuetify.breakpoint.name + (showSuggestions ? ' with-suggestions' : '')">
      <p :class="artistNameClass" >{{artistsNames}}</p>
      <p :class="titleClass">{{title}}</p>
      <p :class="roomClass">{{room}}</p>

    </div>
    <div v-if="artwork && !showTitles" :class="'backdrop-blur artworkName ' + $vuetify.breakpoint.name + (showSuggestions ? ' with-suggestions' : '')" style="padding: 4px 8px !important;">
      <p :class="smallTitleClass">{{title}} - {{artistsNames}} - {{room}}</p>
    </div>

    <div v-if="highlightsEnabled" style="top: 0; left: 0; pointer-events: none; z-index: 7" class="hsp-cont">
      <div v-for="hsp in hotspots" :key="hsp._id" :class="hspTooltip[hsp._id] || (selectedHotspot && selectedHotspot._id === hsp._id)? 'hsp hover' : 'hsp'" :style="hspStyle(hsp)" v-if="!selectedHotspot || selectedHotspot._id === hsp._id">
        <v-tooltip v-model="hspTooltip[hsp._id]" top color="secondary" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="z-index: 9; pointer-events: all" v-bind="attrs" v-on="on" x-small icon color="accent" absolute class="toggle" @click="zoomToHotspot(hsp)"><v-icon>mdi-arrange-send-to-back</v-icon></v-btn>
          </template>
          <span>{{getCategoryName(hsp)}}</span>
        </v-tooltip>
      </div>
    </div>

    <canvas ref="measureCanvas" v-if="measureMode" :width="canvasWidth" :height="canvasHeight"
            class="measure-canvas" @mousedown="startMeasuring" @mouseup="endMeasuring" @mousemove="duringMeasuring"
            @touchstart="touchHandler" @touchmove="touchHandler" @touchend="touchHandler" @touchcancel="touchHandler"
    ></canvas>


    <span v-if="measureMode && currentMeasure" class="measure-tooltip" :style="measureTooltipStyle">{{currentMeasure}}</span>

    <div v-if="measureMode" class="backdrop-blur" style="position:absolute; top: 10px; left: 50%; width: 200px; margin-left: -100px; text-align: center; pointer-events: none">
      <p class="pa-2 ma-2 regular title">{{L('Click and drag on artwork to measure it')}}</p>
    </div>

    <div v-if="!fullscreen" class="d-flex flex-row ma-3" style="position: absolute; top: 0; left :0; z-index: 8">
      <v-btn style="pointer-events: all" icon class="backdrop-blur" @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn>
      <v-btn icon class="backdrop-blur ml-2" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
    </div>

    <div :style="showSuggestions ? 'bottom: 308px;' : 'bottom: 8px;'" class="viewer-toolbar d-flex justify-end align-center full-width">


      <v-tooltip v-model="tooltips.zoomin" v-if="!virtualTourMode" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="$refs.xlviewer.zoomIn()"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
        </template>
        <span>{{L('zoom in')}}</span>
      </v-tooltip>

      <div class="viewer-toolbar-item ml-2">{{zoomPercent}}%</div>

      <v-tooltip v-model="tooltips.zoomout" v-if="!virtualTourMode" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon  @click="$refs.xlviewer.zoomOut()"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
        </template>
        <span>{{L('zoom out')}}</span>
      </v-tooltip>

      <v-tooltip v-model="tooltips.reset" v-if="!virtualTourMode" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon  @click="$refs.xlviewer.reset()"><v-icon>mdi-autorenew</v-icon></v-btn>
        </template>
        <span>{{L('reset view')}}</span>
      </v-tooltip>

      <v-tooltip v-model="tooltips.measure"  v-if="!virtualTourMode && isMeasureAvailable" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon  @click="toggleMeasure()" :color="measureMode ? 'accent' : ''"><v-icon>mdi-altimeter</v-icon></v-btn>
        </template>
        <span>{{L('measure')}}</span>
      </v-tooltip>
      <v-tooltip v-model="tooltips.fullscreen"  v-if="!virtualTourMode" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon  @click="toggleFullscreen()"><v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon><v-icon v-else>mdi-fullscreen</v-icon></v-btn>
        </template>
        <span>{{L('fullscreen')}}</span>
      </v-tooltip>
      <v-tooltip v-model="tooltips.virtualtour" v-if="hasVirtualTour && !virtualTourMode && !fullscreen" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon  @click="startVirtualTour()"><v-icon>mdi-account-voice</v-icon></v-btn>
        </template>
        <span>{{L('virtual tour')}}</span>
      </v-tooltip>
      <v-tooltip v-model="tooltips.play" v-if="virtualTourMode && vitualTourPaused" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="playVirtualTour(true)"><v-icon>mdi-play</v-icon></v-btn>
        </template>
        <span>{{L('play')}}</span>
      </v-tooltip>
      <v-tooltip v-model="tooltips.pause" v-if="virtualTourMode && !vitualTourPaused" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="pauseVirtualTour(true)"><v-icon>mdi-pause</v-icon></v-btn>
        </template>
        <span>{{L('pause')}}</span>
      </v-tooltip>
      <v-tooltip v-model="tooltips.stop" v-if="virtualTourMode" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="stopVirtualTour()"><v-icon>mdi-stop</v-icon></v-btn>
        </template>
        <span>{{L('stop')}}</span>
      </v-tooltip>


      <v-btn class="viewer-toolbar-item backdrop-blur ml-6" text v-if="!virtualTourMode && areSuggestionsLicensed && !fullscreen" :color="showSuggestions ? 'accent' : 'white'" @click="setOpenSuggestions(true)">{{L('suggestions')}}</v-btn>
      <v-tooltip v-model="tooltips.highlights" v-if="!virtualTourMode && canViewHighlights" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :color="highlightsEnabled ? 'accent' : ''" class="viewer-toolbar-item backdrop-blur ml-2" icon @click="highlightsEnabled = !highlightsEnabled"><v-icon>mdi-view-dashboard</v-icon></v-btn>
        </template>
        <span>{{L('highlights details')}}</span>
      </v-tooltip>

      <v-tooltip v-model="tooltips.info" v-if="!virtualTourMode && !fullscreen" top color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="viewer-toolbar-item backdrop-blur ml-6 mr-3" icon @click="setOpenInfo(true)"><v-icon>mdi-information-variant</v-icon></v-btn>
        </template>
        <span>{{L('info')}}</span>
      </v-tooltip>
    </div>

    <v-progress-linear v-if="virtualTourMode" :value="audioProgress" absolute bottom class="full-width"></v-progress-linear>

    <v-bottom-sheet v-model="showSuggestions" hide-overlay persistent >
      <v-sheet id="suggestions-bar" height="300px" style="position: relative" color="secondary">
        <v-row class="full-width fill-height pl-2 pr-12">
          <v-col cols="6" sm="4" lg="2" style="height: 100%; overflow-y: auto" class="pa-0" v-dragscroll.y="true"
                 @dragscrollend="scrollEnd" @dragscrollmove="scrollMoveY">
            <v-list class="secondary">

              <v-list-item v-for="ctg in suggestionsCategories" :key="ctg._id + seed" @click="onCtgClick(ctg)" :class="ctg._id === currentSuggestionsCategory ? 'primary': ''">

                <v-list-item-content >
                  <v-skeleton-loader loading type="list-item" v-if="ctg.isSkeleton"/>
                  <v-list-item-title v-else class="white--text">{{ctg.name[language]}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" sm="8" lg="10" style="height: 100%; overflow-x: auto; overflow-y: hidden" v-dragscroll.x="true"
                 @dragscrollend="scrollEnd" @dragscrollmove="scrollMoveX"
                 class="pa-0">
            <v-container grid-list-md class="pa-3">
              <v-layout row class="flex-nowrap">
                <v-col class="hsp-thumb text-xs-center flex-shrink-0" v-for="hsp in items" :key="hsp._id + seed"  >

                  <div>
                    <v-responsive :aspect-ratio="1.15">
                      <v-skeleton-loader v-if="hsp.isSkeleton" type="card" loading width="200"/>
                      <v-lazy v-else>
                        <v-card @click="goToHotspot(hsp)" width="200">
                          <v-responsive :aspect-ratio="1.15">
                            <v-img :aspect-ratio="4/3" :src="hotspotThumb(hsp)" contain style="background: black">
                              <div class="d-flex justify-end full-width pa-2">
                                <v-tooltip top color="secondary">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" :class="'compare-icon-btn ' + (inCompare(hsp._id) ? 'accent' : '')" fab x-small @click.stop="compareArtworkAndHotspot(hsp)"><v-icon>mdi-compare</v-icon></v-btn>
                                  </template>
                                  <span>{{L('compare')}}</span>
                                </v-tooltip>
                              </div>


                            </v-img>
                            <v-card-title class="subtitle-2" style="line-height: 1">
                              {{getTitle(hsp.artwork)}}
                            </v-card-title>
                            <v-card-subtitle class="accent--text caption pt-2" style="line-height: 1" v-if="hsp.artwork">
                              {{getArtistsNames(hsp.artwork.artist_ids )}}
                              <span class="d-block primary--text font-italic pt-2" style="line-height: 1" v-if="hsp.category">{{ hsp.category.name[language] }}</span>
                            </v-card-subtitle>
                          </v-responsive>
                        </v-card>
                      </v-lazy>
                    </v-responsive>
                  </div>

                </v-col>
              </v-layout>
            </v-container>
          </v-col>
        </v-row>
        <v-btn @click="setOpenSuggestions(false)" icon right top absolute class="ma-0"><v-icon>mdi-chevron-down</v-icon></v-btn>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog v-model="showInfo" width="80%" scrollable style="z-index: 501" >
      <v-card class="backdrop-blur pa-6 relative">
        <v-card-title>
          <div>
            <h3 class="display-1 mb-0 font-weight-regular">{{title}}</h3>
            <h4 class="headline mb-0 font-weight-regular accent--text">{{artistsNames}}</h4>
            <p class="title mb-5 font-weight-medium" v-if="dating">{{dating}}</p>
          </div>

        </v-card-title>
        <v-card-text class="relative" style="height: 80%">
          <v-row>
            <v-col cols="12" lg="8" v-if="artwork" class="white--text" >

              <p class="title mb-0 font-weight-medium font-italic" v-if="technique">{{technique}}</p>
              <p class="title mb-0 font-weight-medium" v-if="room">{{room}}</p>
              <p class="title mb-5 font-weight-medium" v-if="size">{{size}}</p>
              <p class="body-1 mb-5 font-weight-regular pre">{{description}}</p>
              <p class="body-1 mb-0 font-weight-regular" v-if="artwork.signature"><span class="grey--text">{{L('Signature')}}:</span> {{artwork.signature}}</p>
              <p class="body-1 mb-0 font-weight-regular grey--text" v-if="artwork.attachments && artwork.attachments.length">{{L('Other contents')}}:</p>
              <ul v-if="artwork.attachments && artwork.attachments.length">
                <li v-for="(att, i) in artwork.attachments" :key="`${i}_${att.url}`"><a :href="att.url">{{att.title}}</a></li>
              </ul>
            </v-col>
            <v-col cols="12" lg="4"  class="relative" >
              <v-img ref="donninaWrap" :aspect-ratio="2" contain :src="thumb" class="mt-4 black" @load="initObserver()"></v-img>

              <v-img :style="`position: absolute; right: 0; top: ${donninaTop}px`" v :src="donninaSource" :width="donninaWidth" :height="donninaHeight" contain ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn icon style="position: fixed; right: 16px; top: 16px ;" @click="setOpenInfo(false)"><v-icon>mdi-close</v-icon></v-btn>
      </v-card>


    </v-dialog>


    <v-dialog v-model="virtualTourChooser" persistent max-width="400" >
      <v-card>
        <v-card-title class="headline">{{L('Choose a tour')}}</v-card-title>
        <v-card-text>
          <v-select v-model="vtOpt" :items="vtOptions" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="virtualTourChooser = false">{{L('Cancel')}}</v-btn>
          <v-btn color="accent" text @click.native="selectVirtualTour()">{{ L('Select') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import moment from 'moment'
import config from '../config'
import utils from '../utils'
import log from '../log'
import {mapActions, mapGetters, mapState} from 'vuex'
import XLviewer from "../components/XLviewer";


import cm40measure from '../assets/40cm.png'
import cm100measure from '../assets/1m.png'
import cm200measure from '../assets/2m.png'

export default {

  components : {
    "xl-viewer" : XLviewer
  },
  name: 'Artwork',
  data() {
    return {
      zoomPercent : 0,
      loading : false,
      dumping : false,
      dumpLoop : null,
      hspTooltip : {},
      selectedHotspot : null,
      selectedHotspotWait : null,
      tooltips : {
        zoomin : false,
        zoomout : false,
        reset : false,
        measure : false,
        fullscreen : false,
        info : false,
        annotations : false,
        virtualtour : false,
        play : false,
        pause : false,
        stop : false,
        highlights : false
      },
      highlightsEnabled : false,
      refreshEmojis : 10000,
      fullscreen : false,
      scrolling : false,
      bounds : null,
      showSuggestions : false,
      showInfo : false,
      showTitles: true,
      imgfParams : {
        path : null,
        name : null
      },
      artwork : null,
      currentSuggestionsCategory : null,
      suggestions : {},
      hotspots : [],
      items : [],
      suggestionsCategories : [],
      suggestionsTimeout : false,
      vtLicense : {
        isLicensed : false,
        allLicensed : false,
        languages : [],
      },
      virtualTourMode : false,
      vitualTourPaused : true,
      virtualTourChooser : false,
      selectedVirtualTour : null,
      vtOpt : null,
      virtualTours : [],
      audioTrack : null,
      audioProgress : 0,
      lastTime : 0,
      maxTime : 0,
      measuring : false,
      measureMode : false,
      startMeasure : { x : null, y : null },
      endMeasure : { x : null, y : null },
      measuringInterval : null,
      canvasWidth : window.innerWidth,
      canvasHeight : window.innerHeight,
      touchId : null,
      arDonnine : {
        cm40 : 1.3,
        cm100 : 0.76178,
        cm200 : 0.616647,
      },
      infoThumbMaxWidth : 0,
      infoThumbMaxHeight : 0,
      observer : null,

    }
  },
  watch: {
    query: {
      deep: true,
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.applyFilter, 500);
      }
    },
    language() {
      if(this.measureMode) {
        this.toggleMeasure();
      }
    },
    showSuggestions() {
      if(this.showSuggestions) {
        this.loadSuggestions();
      } else {
        this.suggestions = {};
        this.items = [];
        this.suggestionsCategories = [];
      }
    }
  },
  computed : {
    ...mapState(['compareItems']),
    ...mapGetters(['artists','pendingHotspot','language','profile','userLicenses','languageMap','measureUnit','measureRatio', 'categories','collections']),
    seed() {
      return new Date().getTime().toString();
    },
    canViewHighlights() {
      if(!this.artwork) return false;
      let filter = this.userLicenses.filter(item =>{
        return item.hotspot_toggle && this.artwork.collection_ids.includes(item.collection_id) && (item.artworks.all || item.artworks.list.includes(this.artwork._id))
      })
      return filter.length > 0
    },
    artistNameClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 'ml-2 body-1 mb-0 regular accent--text text-right'
        case "md":
          return 'ml-2 body-1 mb-0 regular accent--text'
      }
      return 'ml-2 headline mb-0 regular accent--text'
    },
    titleClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 'ml-2 title mb-0 regular text-right'
        case "md":
          return 'ml-2 title mb-0 regular'
      }
      return 'ml-2 display-1 mb-0 regular'
    },
    roomClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 'ml-2 body-1 mb-0 regular text-right'
        case "md":
          return 'ml-2 body-1 mb-0 regular'
      }
      return 'ml-2 title mb-0 regular'
    },
    smallTitleClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 'caption mb-0 regular text-right'
        case "md":
          return 'caption mb-0 regular'
      }
      return 'body-2 mb-0 regular'
    },
    drawer : {
      get() { return this.$store.state.drawer; },
      set(newVal) {
        if(this.measureMode) {
          this.toggleMeasure();
        }
        this.$store.state.drawer = newVal;
      }
    },
    mainCollection : {
      get() { return this.$store.state.mainCollection; },
      set(newVal) { this.$store.state.mainCollection = newVal; }
    },
    thumb() {
      if(!this.artwork) {
        return null;
      }
      return `${this.artwork.imgfUrl}?cmd=thumb&q=90`;
    },
    donninaSource() {
      if(!this.artwork || this.artwork.width === 0 || this.artwork.height === 0) {
        return null
      }
      let realW = this.artwork.width;
      let realH = this.artwork.height;
      let realAR = realW / realH

      let pxW = this.infoThumbMaxWidth;
      let pxH = this.infoThumbMaxHeight;

      if (realAR > 2) {
        pxH = pxW / realAR
      } else {
        pxW = pxH * realAR
      }

      if(realH <= 40) {
        return cm40measure;
      }
      if(realH <= 100) {
        return cm100measure;
      }
      return cm200measure
    },
    donninaWidth() {
      if(!this.artwork || this.artwork.width === 0 || this.artwork.height === 0) return 0
      let realW = this.artwork.width;
      let realH = this.artwork.height;
      let realAR = realW / realH
      let pxW = this.infoThumbMaxWidth;
      let pxH = this.infoThumbMaxHeight;
      if (realAR > 2) {
        pxH = pxW / realAR
      } else {
        pxW = pxH * realAR
      }
      let donninaW = 200 * this.arDonnine.cm200
      if(realH <= 40) {
        donninaW = 40 * this.arDonnine.cm40
      }
      if(realH <= 100) {
        donninaW = 100 * this.arDonnine.cm100
      }
      return donninaW * pxW / realW;
    },
    donninaHeight() {
      if(!this.artwork || this.artwork.width === 0 || this.artwork.height === 0) return 0
      let realW = this.artwork.width;
      let realH = this.artwork.height;
      let realAR = realW / realH

      let pxW = this.infoThumbMaxWidth;
      let pxH = this.infoThumbMaxHeight;

      if (realAR > 2) {
        pxH = pxW / realAR
      } else {
        pxW = pxH * realAR
      }
      if(realH <= 40) {
        return 40 * pxH / realH
      }
      if(realH <= 100) {
        return 100 * pxH / realH;
      }
      return 200 * pxH / realH;
    },
    donninaTop() {
      if(this.donninaHeight > this.infoThumbMaxHeight) {
        return 32;
      }
      return Math.round(32 + (this.infoThumbMaxHeight - this.donninaHeight));
    },
    title() {
      const lng = this.language;
      return this.artwork && this.artwork.title ? this.artwork.title[lng] || this.artwork.title.en || this.artwork.title.it : '';
    },
    dating() {
      if(!this.artwork || !this.artwork.date_display) return '';
      const lng = this.language;
      return this.artwork.date_display[lng] ? this.artwork.date_display[lng] : this.artwork.date_display.it ? this.artwork.date_display.it : this.artwork.date_display.en ? this.artwork.date_display.en : '';
    },
    room() {
      let room = ''
      let f = this.artwork.collection_ids.filter(c => c !== config.collection)
      if(f.length) {
        let collection = utils.getObjectInArray(this.collections,'_id', f[0]);
        room = collection && collection.title ? collection.title[this.language] || collection.title.en || collection.title.it : '';
      }
      if(!this.artwork.room_name) return room;
      const lng = this.language;
      const roomName = this.artwork.room_name[lng] || this.artwork.room_name.en || this.artwork.room_name.it || ''

      if(roomName && roomName.includes(room)) {
        return roomName;
      } else if(roomName && room) {
        return `${room}, ${roomName}`
      } else if(room) {
        return room;
      }
      return roomName;
    },
    size() {
      if(!this.artwork.size_display) return '';
      const lng = this.language;
      return this.artwork.size_display[lng] ? this.artwork.size_display[lng] : this.artwork.size_display.it ? this.artwork.size_display.it : this.artwork.size_display.en ? this.artwork.size_display.en : '';
    },
    technique() {
      if(!this.artwork.technique) return '';
      const lng = this.language;
      return this.artwork.technique[lng] ? this.artwork.technique[lng] : this.artwork.technique.it ? this.artwork.technique.it : this.artwork.technique.en ? this.artwork.technique.en : '';
    },
    description() {
      const lng = this.language;
      return this.artwork.desc[lng] ? this.artwork.desc[lng] : this.artwork.desc.it ? this.artwork.desc.it : this.artwork.desc.en ? this.artwork.desc.en : '<no translation>';
    },
    artistsNames() {
      return this.getArtists(this.artwork)
    },
    currentVirtualTours() {
      return this.virtualTours.filter((item)=>{
        return item.language_code === this.language;
      })
    },
    hasVirtualTour() {
      return this.currentVirtualTours.length > 0;
    },
    vtOptions() {
      return this.currentVirtualTours.map((item) => {
        return { value: item._id, text : item.title };
      })
    },
    areSuggestionsLicensed() {
      if(!this.artwork) return false;
      if(this.profile && this.profile.role !== 'user') return true
      for(let i = 0; i < this.userLicenses.length; i++){
        const lic = this.userLicenses[i];
        if(this.artwork.collection_ids.includes(lic.collection_id) && lic.suggestions) return true;
      }
      return false;
    },
    isMeasureAvailable() {
      return this.artwork && this.artwork.width && this.artwork.height;
    },
    currentMeasure() {
      if(!this.isMeasureAvailable || this.startMeasure.x === null || this.startMeasure.y === null || this.endMeasure.x === null || this.endMeasure.y === null) return "";
      //let pixelDist = utils.calculateDistanceBetweenTwoPoints(this.startMeasure, this.endMeasure)
      let measure = this.$refs.xlviewer.measure(this.startMeasure, this.endMeasure, this.artwork.width, this.artwork.height) / this.measureRatio;
      return`${measure.toFixed(2)} ${this.measureUnit}`;
    },
    measureTooltipStyle() {
      if(!this.currentMeasure) return "display: none;";
      return `top: ${this.endMeasure.y+4}px; left:${this.endMeasure.x+4}px`;
    }
  },
  methods: {
    ...mapActions(['load','get','sendError','refreshCollection','setPendingHotspot','getSuggestions',
      'isArtworkVTLicensed','insert','update','remove','compare']),
    L( key ) {
      return this.languageMap[key] || key;
    },
    inCompare( id ) {
      for(let i = 0; i < this.compareItems.length; i++) {
        if(this.compareItems[i]._id === id || (this.compareItems[i].hotspot && this.compareItems[i].hotspot._id === id)) return true
      }
      return false;
    },
    compareArtworkAndHotspot(hotspot) {
      this.$store.state.compareItems = [];
      this.compare({item:this.artwork})
      this.compare({item:hotspot.artwork, hotspot : hotspot})
    },
    hspStyle(hsp) {
      let topLeft = this.$refs.xlviewer.getLocalPoint(hsp.x,hsp.y);
      let bottomRight = this.$refs.xlviewer.getLocalPoint(hsp.x+hsp.w,hsp.y+hsp.h);
      return `top: ${topLeft.y}px; left: ${topLeft.x}px; width: ${bottomRight.x - topLeft.x}px;  height: ${bottomRight.y - topLeft.y}px`;
    },
    getCategoryName( hsp ) {
      if(!hsp || !hsp.category || ! hsp.category.name) return '';
      return hsp.category.name[this.language] || hsp.category.name.en || hsp.category.name.it;
    },
    initObserver() {
      this.$nextTick(()=>{
        const box = this.$refs.donninaWrap.$el;
        // create the observer
        this.infoThumbMaxWidth = box.clientWidth;
        this.infoThumbMaxHeight = box.clientHeight;
        const observer = new ResizeObserver((mutations) => {
          this.infoThumbMaxWidth = mutations.contentRect.width;
          this.infoThumbMaxHeight = mutations.contentRect.height;
        });

        // observe element's specified mutations
        observer.observe(box);
        // add the observer to data so we can disconnect it later
        this.observer = observer;
      });

    },
    toggleFullscreen(){
      this.drawer = false;
      this.$fullscreen.toggle(this.$refs.wrap.$el, {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange( isFullscreen ) {
      this.fullscreen = isFullscreen;
    },
    setOpenSuggestions( value ) {
      this.showSuggestions = value;
    },
    setOpenInfo( value ) {
      this.showInfo = value;
    },
    touchHandler(event) {
      let touches = event.changedTouches,
          first = touches[0],
          type = "";
      switch(event.type)
      {
        case "touchstart": type = "mousedown"; break;
        case "touchmove":  type = "mousemove"; break;
        case "touchend":   type = "mouseup";   break;
        default:           return;
      }

      // initMouseEvent(type, canBubble, cancelable, view, clickCount,
      //                screenX, screenY, clientX, clientY, ctrlKey,
      //                altKey, shiftKey, metaKey, button, relatedTarget);

      let simulatedEvent = document.createEvent("MouseEvent");
      simulatedEvent.initMouseEvent(type, true, true, window, 1,
          first.screenX, first.screenY,
          first.clientX, first.clientY, false,
          false, false, false, 0/*left*/, null);

      first.target.dispatchEvent(simulatedEvent);
      event.preventDefault();
    },
    toggleMeasure() {
      this.startMeasure = { x : null, y: null }
      this.endMeasure = { x : null, y: null }
      this.measureMode = !this.measureMode;
    },
    startMeasuring(e) {
      this.measuring = true;
      const offsets = this.$refs.xlviewer.$el.getBoundingClientRect();
      this.startMeasure.x = e.clientX - offsets.left;
      this.startMeasure.y = e.clientY - offsets.top;
      this.endMeasure.x = e.clientX - offsets.left;
      this.endMeasure.y = e.clientY - offsets.top;
      this.drawMeasure();
    },
    drawMeasure() {
      const canvas = this.$refs.measureCanvas;
      const ctx = this.$refs.measureCanvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if(!this.measuring) return;
      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.strokeStyle = '#f49f00';
      ctx.moveTo(this.startMeasure.x,this.startMeasure.y);
      ctx.lineTo(this.endMeasure.x,this.endMeasure.y);
      ctx.stroke();
      ctx.closePath();
    },
    duringMeasuring(e) {
      if(!this.measuring) return;
      const offsets = this.$refs.xlviewer.$el.getBoundingClientRect();
      this.endMeasure.x = e.clientX - offsets.left;
      this.endMeasure.y = e.clientY - offsets.top;
      this.drawMeasure();
    },
    endMeasuring(e) {
      if(!this.measuring) return;
      const offsets = this.$refs.xlviewer.$el.getBoundingClientRect();
      this.endMeasure.x = e.clientX - offsets.left;
      this.endMeasure.y = e.clientY - offsets.top;
      this.drawMeasure();
      this.measuring = false;
    },
    formatDateTime( date ) {
      return utils.formatDateTime(date)
    },
    scrollEnd() {
      setTimeout(() => {
        this.scrolling = false;
      }, 500)

    },
    scrollMoveX(e) {
      if(Math.abs(e.detail.deltaX) >= 2) {
        this.scrolling = true;
      }
    },
    scrollMoveY(e) {
      if(Math.abs(e.detail.deltaY) >= 2) {
        this.scrolling = true;
      }
    },
    getTitle(item) {
      if(!item) return '';
      const lng = this.language;
      const title = item.title[lng] ? item.title[lng] : item.title.it ? item.title.it : item.title.en ? item.title.en : '<no translation>';
      return utils.capText(title, 26);
    },
    getArtists(item) {
      if(!item) return '';
      if(!item.artists_names || !item.artists_names.length) {
        return this.L('unknown artist');
      }
      const lng = this.language
      return item.artists_names.map((names) => {
        return names[lng] ? names[lng] : names.it ? names.it : names.en ? names.en : '<no translation>'
      }).join(", ")
    },
    getArtistsNames(ids) {
      let artistName = "";
      for(let i = 0; i < ids.length; i++) {
        let id = ids[i];
        let artist = this.artists.filter((item) => item._id === id).shift();
        if(!artist) continue;
        if(artist.name[this.language]) {
          if(artistName.length > 0) {
            artistName += ", ";
          }
          artistName += artist.name[this.language]
        }
      }
      return artistName;
    },
    hotspotThumb(hsp) {
      return utils.xlimageThumbHotspotURL(hsp.imgfUrl, hsp, 256, 256, 90)
      //hsp.imgfUrl+'?cmd=thumb&q=70'
    },

    onImageOpen() {
      if(this.pendingHotspot) {
        setTimeout(() => {
          let hsp = this.pendingHotspot;
          this.$refs.xlviewer.zoomToBounds(hsp.x, hsp.y, hsp.w, hsp.h, false, true);
          this.setPendingHotspot({hotspot: null});
        }, 1000);
      }
      this.loadSuggestions();
    },

    onViewportChange() {
      this.bounds = this.$refs.xlviewer.getBounds(true);
      this.zoomPercent = this.$refs.xlviewer.getZoomPercent();
      this.showTitles = !(this.bounds.width < 0.98 || this.bounds.height < 0.98);
      clearTimeout(this.suggestionsTimeout);
      this.suggestionsTimeout = setTimeout(this.loadSuggestions, 500 );


      if(this.selectedHotspotWait && moment().isAfter(this.selectedHotspotWait)) {
        this.selectedHotspotWait = null;
        this.selectedHotspot = null;
      }

    },
    onCtgClick(ctg) {
      if(ctg.isSkeleton || this.scrolling) return;
      this.showSuggestionsOf(ctg)
    },
    showSuggestionsOf(ctg) {
      log.d('showSuggestionsOf()', ctg)
      const id = _.isString(ctg) ? ctg : ctg._id
      this.currentSuggestionsCategory = id;
      this.items = this.suggestions.hotspots && this.suggestions.hotspots[id] ? this.suggestions.hotspots[id] : [];
    },
    hasCategory( id ) {
      return this.suggestionsCategories.filter((item) => item._id === id).length > 0;
    },
    async loadHotspotsOfArtwork() {
      if(!this.canViewHighlights) return;
      log.d("loadHotspotsOfArtwork()")
      try {
        let reply = await this.get({collection:'artworks', id:`${this.$route.params.id}/hotspots`})
        if(reply.data) this.hotspots = reply.data;
      }catch (e) {
        log.d(e)
      }
    },
    loadSuggestions() {
      if(!this.areSuggestionsLicensed || !this.showSuggestions) return;
      this.suggestions = {};
      this.items = [];
      this.suggestionsCategories = [];

      this.addSkeletons(20)

      if(!this.bounds) {
        this.bounds = this.$refs.xlviewer.getBounds(true);
      }

      const data = {
        artwork_id : this.$route.params.id,
        x : this.bounds.x,
        y : this.bounds.y,
        w : this.bounds.width,
        h : this.bounds.height,
        restrict_x : config.restrictRatio.x,
        restrict_y : config.restrictRatio.y,
        zoom_percent : this.$refs.xlviewer.getZoomPercent()
      };

      this.getSuggestions({ data, collection : this.mainCollection }).then((reply) => {
        log.d("suggestions results", reply)
        this.suggestions = reply;
        this.suggestionsCategories = this.suggestions.categories || []
        this.removeSkeletons();
        if(this.currentSuggestionsCategory && this.hasCategory(this.currentSuggestionsCategory)) {
          this.showSuggestionsOf(this.currentSuggestionsCategory);
        } else if(this.suggestionsCategories.length) {
          this.showSuggestionsOf(this.suggestionsCategories[0]._id);
        }

      }).catch((err) => {
        this.removeSkeletons();
        utils.checkSessionError(this, err)
        log.d("get suggestions err", err)
        //this.sendError({message: err.message || "c'è stato un errore alla richiesta dei suggerimenti"});
      });

    },
    goToHotspot(hsp) {
      if(this.scrolling) return;
      if(hsp.artwork._id === this.$route.params.id) {
        this.$refs.xlviewer.zoomToBounds(hsp.x, hsp.y, hsp.w, hsp.h, false, true);
        return
      }
      this.setPendingHotspot({ hotspot : hsp });
      //this.$router.replace("/artworks/"+hsp.artwork._id)
      this.$router.replace("/artworks/"+hsp.artwork._id)
    },
    addSkeletons(length) {
      this.items.push(...Array.from({length}, (v, i) => {
        return { _id : 'skt_' + i, isSkeleton : true };
      }));
      this.suggestionsCategories.push(...Array.from({length}, (v, i) => {
        return { _id : 'skl_' + i, isSkeleton : true };
      }));
    },
    removeSkeletons() {
      this.items = this.items.filter((item) => !item.isSkeleton);
      this.suggestionsCategories = this.suggestionsCategories.filter((item) => !item.isSkeleton);
    },
    zoomToHotspot( hsp ) {
      let deltaW = hsp.w * 0.1;
      let deltaH = hsp.h * 0.1;
      let x = Math.max(hsp.x - deltaW, 0);
      let w = Math.min(hsp.w + (2*deltaW), 1);
      let y = Math.max(hsp.y - deltaH, 0);
      let h = Math.min(hsp.h + (2*deltaH), 1);
      this.$refs.xlviewer.zoomToBounds(x, y, w, h, false, true);
      this.selectedHotspot = hsp;
      this.selectedHotspotWait = moment().add(2, 'seconds')
      let parent = hsp.category ? hsp.category : utils.getObjectInArray(this.categories, '_id', hsp.category_id);
      let numLoops = 0
      while (parent.parent_id) {
        parent = utils.getObjectInArray(this.categories, '_id', parent.parent_id);
        numLoops++;
        // evitiamo sorprese da spazzatura nel db
        if(numLoops > 20) break;
      }

      this.currentSuggestionsCategory = parent._id
      this.setOpenSuggestions(true)
    },
    onAudioLoaded(e){
      this.maxTime = this.audioTrack.duration;
    },
    onAudioEnded(e){
      this.virtualTourMode = false;
      this.vitualTourPaused = true;
    },
    onAudioUpdate(e){
      const currentTime = Math.floor(this.audioTrack.currentTime);
      if(this.lastTime === currentTime) {
        return;
      }
      if(this.maxTime > 0) this.audioProgress = Math.round(100 / this.maxTime * this.audioTrack.currentTime)

      const minutes = Math.floor(currentTime/60);
      const seconds = currentTime - (minutes * 60);

      const hotspots = this.selectedVirtualTour.hotspots;
      for(let i = 0; i < hotspots.length; i++) {
        const hsp = hotspots[i];
        if(hsp.minutes == minutes && hsp.seconds == seconds) {
          this.$refs.xlviewer.zoomToBounds(hsp.x, hsp.y, hsp.w, hsp.h, false, true)
          return;
        }
      }
    },
    selectVirtualTour() {
      this.selectedVirtualTour = null;
      if(this.vtOpt) {
        for(let i = 0; i < this.currentVirtualTours.length; i++) {
          const item = this.currentVirtualTours[i];
          if(this.vtOpt === item[i]) {
            this.selectedVirtualTour = item;
            break
          }
        }
      }
      this.virtualTourChooser = false;
      if(this.selectedVirtualTour) {
        this.audioTrack.src = this.selectedVirtualTour.audio.url;
        this.audioTrack.preload = true;
        this.playVirtualTour(false);
      }

    },
    startVirtualTour() {
      this.lastTime = this.maxTime = 0;
      this.audioProgress = 0;
      if(this.audioTrack) {
        this.audioTrack.pause();
        this.audioTrack.currentTime = 0;
      }

      if(this.currentVirtualTours.length === 1) {
        this.selectedVirtualTour = this.currentVirtualTours[0];
        this.audioTrack.src = this.selectedVirtualTour.audio.url;
        this.audioTrack.preload = true;
        this.playVirtualTour(false);
        return;
      }
      this.virtualTourChooser = true;
    },
    playVirtualTour(sendEvent) {
      this.$refs.xlviewer.reset();
      this.audioTrack.play();
      this.virtualTourMode = true;
      this.highlightsEnabled = false;
      this.vitualTourPaused = false;
    },
    pauseVirtualTour(sendEvent) {
      this.audioTrack.pause();
    },
    stopVirtualTour() {
      this.pauseVirtualTour(false);
      this.virtualTourMode = false;
      this.vitualTourPaused = true;
    },
    loadData() {
      let id = this.$route.params.id + '?extras=true';
      this.get({collection : 'artworks', id}).then((reply)=>{
        if(reply.success) {
          this.artwork = reply.data;
          let imgfUrl = this.artwork.imgfUrl;
          this.imgfParams = utils.splitUrl(imgfUrl);

          this.isArtworkVTLicensed({ artwork : this.artwork }).then(( { isLicensed, allLicensed, languages } )=>{
            this.vtLicense = { isLicensed, allLicensed, languages };

            if(isLicensed) {
              this.get({collection : 'artworks', id : this.$route.params.id + "/vt"}).then((reply)=>{
                this.virtualTours = reply.data.filter((item)=>{
                  if(this.vtLicense.allLicensed) return true;
                  return this.vtLicense.languages.includes(item.language_code);
                });
              }).catch((log.d))
            }
          })

          this.loadHotspotsOfArtwork()

          return;
        } else {
          this.imgfParams = utils.splitUrl();
        }
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
      }).catch((err)=>{
        if(!utils.checkError(this, err)) return;
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
      })
    },
    resizeCanvas() {
      this.canvasWidth = window.innerWidth
      this.canvasHeight = window.innerHeight
    },

  },
  mounted() {
    this.refreshCollection({collection: 'artists'});
    this.refreshCollection({collection: 'categories'});

    this.showTitles = true;
    this.drawer = false;
    setTimeout(this.loadData, 500);
    this.audioTrack = document.createElement('audio');
    this.audioTrack.addEventListener('timeupdate', this.onAudioUpdate);
    this.audioTrack.addEventListener('loadeddata', this.onAudioLoaded);
    this.audioTrack.addEventListener('ended', this.onAudioEnded);


    window.addEventListener('resize', this.resizeCanvas, false);
  },
  beforeDestroy() {
    if(this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    this.stopVirtualTour();
    window.removeEventListener('resize', this.resizeCanvas, false);
  }
}
</script>
