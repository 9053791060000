<template>
  <v-container >
    <v-row class="ml-6 mr-6 mt-6">
      <v-col cols="12" >
        <h1>{{name}}</h1>
        <h2 class="accent--text mt-1" v-html="displayDating"></h2>
        <v-divider class="mt-6"></v-divider>
        <p class="title font-weight-regular text-justify mt-6 pre">{{description}}</p>
      </v-col>

    </v-row>



  </v-container>
</template>

<script>
import utils from '../utils'
import log from '../log'
import {mapActions, mapGetters, mapState} from 'vuex'
import config from "../config";

export default {
  name: 'Artist',
  data() {
    return {
      item : null,
      items : []
    }
  },
  computed : {
    ...mapState(['compareItems']),
    ...mapGetters(['language','languageMap']),
    name() {
      if(!this.item) return '';
      const lng = this.language;
      let name = this.item.name[lng] ? this.item.name[lng] : this.item.name.it ? this.item.name.it : this.item.name.en ? this.item.name.en : '<no translation>';
      return name.toUpperCase();
    },
    description() {
      if(!this.item) return '';
      const lng = this.language;
      return this.item.description[lng] ? this.item.description[lng] : this.item.description.it ? this.item.description.it : this.item.description.en ? this.item.description.en : '';
    },
    displayDating() {
      if(!this.item) return ''
      let from = this.item.born_place[this.language] ? this.item.born_place[this.language] + " " +
          this.item.born_date[this.language] : this.item.born_date[this.language];
      let to = this.item.death_place[this.language] ? this.item.death_place[this.language] + " " +
          this.item.death_date[this.language] : this.item.death_date[this.language];
      return `${from} <span class="grey--text ml-2 mr-2">-</span> ${to}`;
    },
  },
  methods: {
    ...mapActions(['load','get','sendError','refreshCollection','compare']),
    L( key ) {
      return this.languageMap[key] || key;
    },
    inCompare( id ) {
      for(let i = 0; i < this.compareItems.length; i++) {
        if(this.compareItems[i]._id === id) return true
      }
      return false;
    },
    getTitle(item) {
      const lng = this.language;
      return item.title[lng] ? item.title[lng] : item.title.it ? item.title.it : item.title.en ? item.title.en : '<no translation>';
    },
    getArtists(item) {
      if(!item.artists_names || !item.artists_names.length) {
        return this.L('unknown artist');
      }
      const lng = this.language
      return item.artists_names.map((names) => {
        return names[lng] ? names[lng] : names.it ? names.it : names.en ? names.en : '<no translation>'
      }).join(", ")
    },
    addSkeletons(length) {
      this.items.push(...Array.from({length}, (v, i) => {
        return { _id : 'sk_' + i, isSkeleton : true };
      }));
    },
    removeSkeletons() {
      this.items = this.items.filter((item) => !item.isSkeleton);
    },
    loadData() {
      this.get({collection : 'artists', id: config.artist}).then((reply)=>{
        if(reply.success) {
          this.item = reply.data;
          return;
        }
        this.sendError({message:"errore al caricamento dei dati dell'artista."});
      }).catch((err)=>{
        if(!utils.checkError(this, err)) return;
        this.sendError({message:"errore al caricamento dei dati dell'artista."});
      })
    }
  },
  mounted() {
    this.loadData()
    //setTimeout(this.applyFilter, 500)
  }
}
</script>
