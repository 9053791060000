import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import it from 'vuetify/es5/locale/it';
import en from 'vuetify/es5/locale/en';
import de from 'vuetify/es5/locale/de';
import zhHans from 'vuetify/es5/locale/zh-Hans';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#DB4122',
        secondary: '#383838',
        accent: '#DB4122',
        error: '#FF5252',
        info: '#999999',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#DB4122',
        secondary: '#383838',
        accent: '#DB4122',
        error: '#FF5252',
        info: '#999999',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
    lang: {
      locales: { it, en, de, zhHans },
      current: 'en',
    },
});
