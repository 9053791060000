<template>
  <v-row no-gutters class="fill-height">
    <v-col cols="4" class="pa-6">
      <h1 class="accent--text">{{L('contesto_title')}}</h1>
      <p class="mt-3 body-1 pre">{{L('contesto_desc')}}</p>
    </v-col>
    <v-col cols="8" class="relative timeline fill-height" >
      <timeline ref="timeline" :items="items" :options="options" @changed="onTimelineChanged" @rangechanged="onRangeChanged" @select="onSelectItem"
                @double-click="$refs.timeline.zoomIn(0.3)" class="full-width fill-height"></timeline>
      <v-progress-linear absolute bottom color="accent" indeterminate v-if="loading" height="4"  />

      <div class="d-flex full-width justify-center" style="position: absolute; top: 0; pointer-events: none">
        <v-btn style="pointer-events: auto" fab color="accent" small class="ma-3" @click="$refs.timeline.zoomIn(0.3)"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
        <v-btn style="pointer-events: auto" fab color="accent" small class="ma-3" @click="$refs.timeline.zoomOut(0.3)"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { Timeline } from '@vue2vis/timeline'
import utils from '../utils'
import log from '../log'
import moment from 'moment'
moment.updateLocale('it', {
  eras: [{
    since:  '0001-01-01',
    until:  +Infinity,
    offset: 1,
    name:   'Dopo Cristo',
    narrow: 'DC',
    abbr:   'DC'
  }, {
    until:   -Infinity,
    since:  '0000-12-31',
    offset: 0,
    name:   'Avanti Cristo',
    narrow: 'AC',
    abbr:   'AC'
  }],
});

import {mapActions, mapGetters, mapState} from 'vuex'
import config from "@/config";

export default {
  components : {
    "timeline" : Timeline
  },
  name: 'Timeline2',
  data() {
    return {
      loading : true,
      preventSelect : false,
      refreshTimeout : null,
      refreshOneDone : false,
      count : 0,
      items : [],
      options: {
        moment: moment,
        editable: false,
        width: "100%",
        height: "100%",
        zoomMin : 61536000000,
        zoomMax : 157680000000000,
        max: new Date(),
        min: new Date(-700, 0, 0),
        end: new Date(),
        start: new Date(-500, 0, 0),
        locale : 'en',
        showCurrentTime : false,
        showMajorLabels : false,
        format : {
          minorLabels : {
            year : 'y N'
          }
        },
        template: (item, element, data) => {
          let title = item.data.title[this.language] || item.data.title.en || item.data.title.it || '';
          title = utils.capText(title, 35)
          let dating = item.data.date_display[this.language] || item.data.date_display.en || item.data.date_display.it || '';

          const isMantegna = item.data.artist_ids.includes(config.artist)
          return `
      <div class="${isMantegna ? 'mantegna-thumb' : ''}">
      <div class="absolute d-flex justify-end full-width pa-1 pr-3">
      <button type="button" data-id="${item.data._id}" class="d-inline-flex compare-icon-btn ml-1 v-btn v-btn--contained v-btn--fab v-btn--round theme--dark v-size--x-small" role="button" aria-haspopup="true" aria-expanded="false"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-compare theme--dark"></i></span></button>
      </div>

      <img src="${item.data.imgfUrl}?cmd=thumb&q=75" class="timeline-item" />
      <h4 class="subtitle-2 timeline-item-title">${title}</h4>
      <span class="caption timeline-item-dating info--text mt-1">${dating}</span>
      </div>
      `
        },
        timeAxis :{
          scale : 'year',
          step : 300,
        }
      },
      pagination: {
        page : 1,
        limit : 20
      },
      artworks : {},
    }
  },
  watch: {
    language() {
      moment.locale(this.language)
      this.refreshTimeline();
    }
  },
  computed : {
    ...mapState(['compareItems']),
    ...mapGetters(['artists','techniques','language','languageMap','collection','timelineTree','mainCollection']),
  },
  methods: {
    ...mapActions(['load', 'sendError', 'refreshCollection', 'loadTimeline', 'loadTimelineArtworksOf','compare']),
    L(key) {
      return this.languageMap[key] || key;
    },
    inCompare( id ) {
      for(let i = 0; i < this.compareItems.length; i++) {
        if(this.compareItems[i]._id === id) return true
      }
      return false;
    },
    onAddToLessonItemClick(e) {
      this.preventSelect = true;
      this.addToLessonArtworkId = e.currentTarget.getAttribute('data-id');
      this.addToLessonDialog = true
      setTimeout(()=>{
        this.preventSelect = false;
      }, 1000)
    },
    onCompareItemClick(e) {
      this.preventSelect = true;
      let id = e.currentTarget.getAttribute('data-id')
      log.d("e.target", e.currentTarget)
      let item = utils.getObjectInArray(this.items, 'id', id)
      if(!item) {
        log.d("not item!")
        return;
      }
      this.compare({ item : item.data})
      if(this.inCompare(id)) {
        log.d("inCompare")
        e.currentTarget.classList.add('accent')
      } else {
        e.currentTarget.classList.remove('accent')
      }
      if(this.compareItems.length === 2) {
        let elements = document.getElementsByClassName('compare-icon-btn')
        Array.from(elements).forEach((element) => {
          element.classList.remove('accent')
        });
      }
    },
    voidFilters() {
      this.query = {
        title: null,
        artist: null,
        technique: null,
        collection: null,
        from: null,
        to: null
      }
    },
    getArtists(item) {
      if (!item.artists_names || !item.artists_names.length) {
        return this.L('unknown artist');
      }
      const lng = this.language
      return item.artists_names.map((names) => {
        return names[lng] ? names[lng] : names.it ? names.it : names.en ? names.en : '<no translation>'
      }).join(", ")
    },
    getArtistName(id) {
      const lng = this.language
      const artist = this.artists.filter((item) => item._id === id)[0];
      return artist.name[lng] ? artist.name[lng] : artist.name.it ? artist.name.it : artist.name.en ? artist.name.en : '<no artist translation>'
    },
    getTechniqueName(id) {
      const lng = this.language
      const filter = this.techniques.filter((item) => item._id === id);
      //log.e("filter", filter)
      const tech = filter[0];
      return tech.name[lng] ? tech.name[lng] : tech.name.it ? tech.name.it : tech.name.en ? tech.name.en : '<no technique translation>'
    },
    removeSkeletons() {
      this.items = this.items.filter((item) => !item.isSkeleton);
    },
    onTimelineChanged() {

    },
    onRangeChanged(s) {
      log.d("onRangeChanged()")
      let start = s.start.getFullYear()
      let end = s.end.getFullYear()

      if(this.options.start.getFullYear() === start && this.options.end.getFullYear() === end && this.refreshOneDone) return

      let lapse = end - start;
      if(lapse > 800) {
        this.options.timeAxis.step = 300;
      } else if(lapse > 500) {
        this.options.timeAxis.step = 100;
      } else if(lapse > 100) {
        this.options.timeAxis.step = 50;
      } else if(lapse > 50) {
        this.options.timeAxis.step = 10;
      } else if(lapse > 8) {
        this.options.timeAxis.step = 5;
      } else {
        this.options.timeAxis.step = 1;
      }
      this.options.start = s.start
      this.options.end = s.end

      //console.log(s)
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(this.refreshTimeline, 500);
    },
    async loadTimelineStep(limit, numRows, from, to) {
      let query = {}
      if(this.mainCollection) {
        query.collection = this.mainCollection;
      }
      query.from = from
      query.to = to
      let filter = { query, limit : numRows, page : 1, sort : '-priority'};
      let items = [];
      try {
        let reply = await this.load({collection:'artworks', filter})
        if(reply && reply.data) {
          items = reply.data.map((item)=>{


            let year = Math.floor((item.date_to - item.date_from) / 2) + item.date_from
            //console.log(year)
            return {
              id: item._id,
              start: new Date(year,0,0),
              data : item
            }
          })
        }
      } catch(err) {
        if(!utils.checkError(this, err)) return;
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
        log.w("error loading artworks", err);
      }
      return items;
    },
    async refreshTimeline() {
      log.d("refreshTimeline()")
      this.loading = true;
      this.refreshOneDone = true
      let availableW = this.$refs.timeline.$el.clientWidth * 0.9
      let availableH = this.$refs.timeline.$el.clientHeight * 0.9
      const averageItemW = 130
      const averageItemH = 200
      let numRow = Math.floor(availableH / averageItemH)
      let numCols = Math.floor(availableW / averageItemW)
      let numElements = numRow * numCols

      let yearStep = this.options.timeAxis.step
      let nextStep = this.options.start.getFullYear()
      let items = []
      let promises = []
      while (nextStep < this.options.end.getFullYear()) {
        promises.push(this.loadTimelineStep(numElements, numRow, nextStep, nextStep+yearStep))
        nextStep += yearStep;
      }
      const results = await Promise.allSettled(promises)
      for(let j = 0; j < results.length; j++) {
        let res = results[j];
        if(res.value) {
          let part = res.value.filter((item)=>{
            for(let i = 0; i < items.length; i++) {

              if(items[i].id === item.id) {
                return false
              }
            }
            return true;
          });
          items = items.concat(part)
        }
      }
      this.items = items;
      this.loading = false;
      this.$nextTick(this.applyListeners);
    },
    onSelectItem(e) {
      if(!e.items.length) return;
      setTimeout(()=> {
        if(this.preventSelect) {
          this.preventSelect = false;
          return
        }
        this.preventSelect = false;
        this.$router.push('/artworks/' + e.items[0])
      }, 200)
    },
    applyListeners() {

      let elements = document.getElementsByClassName('compare-icon-btn')
      let lessonElements = document.getElementsByClassName('add-to-lesson-btn')
      Array.from(elements).forEach((element) => {
        element.addEventListener('click', this.onCompareItemClick, true);
      });
      Array.from(lessonElements).forEach((element) => {
        element.addEventListener('click', this.onAddToLessonItemClick, { once : true });
      });
    }
  },
  mounted() {
    const coll = this.collection;
    this.options.min = coll && coll.timeline_from? new Date(coll.timeline_from, 0, 0) : new Date(-700, 0, 0)
    this.options.start = coll && coll.timeline_from? new Date(coll.timeline_from, 0, 0) : new Date(-700, 0, 0)
    this.options.max = coll && coll.timeline_to ? new Date(coll.timeline_to, 0, 0) : new Date()
    this.options.end = coll && coll.timeline_to ? new Date(coll.timeline_to, 0, 0) : new Date()
  }
}
</script>