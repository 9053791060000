<template>
  <v-container ref="container" id="artworks-list-page">
    <v-row >
      <v-col class="artwork-item" :id="`artwork-item-${item._id}`" v-for="item in items" cols="12" sm="6" md="4" lg="4" xl="3" :key="item._id">
        <div>
          <v-responsive :aspect-ratio="4/3">
            <v-skeleton-loader v-if="item.isSkeleton" type="card" loading />
            <v-lazy v-else>
              <v-card @click="$router.push('/artworks/' + item._id)" class="secondary">
                <v-responsive :aspect-ratio="4/3">
                  <v-img :aspect-ratio="16/9" :src="item.imgfUrl + '?cmd=thumb'" contain style="background: black" >
                    <div class="full-width fill-height">
                      <div class="full-width d-flex align-start justify-end absolute top">
                        <v-chip :color="inCompare(item._id) ?  'accent' : '#253835'" class="ma-2 compare" @click.stop="compare({item})">{{L('COMPARE')}}</v-chip>
                      </div>
                    </div>
                  </v-img>
                  <v-card-title class="subtitle-1 text-center justify-center">
                    {{getTitle(item)}}
                  </v-card-title>
                  <v-card-subtitle class="subtitle-1 text-center">
                    <span v-for="(artist_id, i) in item.artist_ids" :key="'art-' + item._id + '-' + artist_id">
                      <span v-if="i > 0">,&nbsp;</span>
                      <a @click.stop="$router.push('/artists/'+artist_id)" class="info--text">{{getArtistName(artist_id)}}</a>
                    </span>
                  </v-card-subtitle>
                </v-responsive>
              </v-card>
            </v-lazy>
          </v-responsive>
        </div>
      </v-col>
    </v-row>

    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div slot="spinner"><v-progress-linear indeterminate class="full-width" color="accent" /></div>
      <div slot="no-more"></div>
      <div slot="no-results" class="mt-6">{{L('No results found')}}</div>
    </infinite-loading>


    <v-dialog v-model="filterDialog" max-width="600" >
      <v-card>

        <v-card-title>
          <h2>{{L('Filters').toUpperCase()}}</h2>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12">
              <v-text-field filled rounded dense append-icon="mdi-magnify" hide-details :label="L('Search')" v-model="searchTerm" clearable color="accent"/>
            </v-col>
            <v-col cols="12">
              <v-autocomplete filled rounded dense hide-details :label="L('Artist')" v-model="query.artist" :items="artistOpts" clearable color="accent"/>
            </v-col>
            <v-col cols="12">
              <v-autocomplete filled rounded dense hide-details :label="L('Technique')" v-model="query.technique" :items="techniqueOpts" clearable color="accent"/>
            </v-col>
            <v-col cols="12">
              <h4>{{ L('Dating') }}</h4>
              <v-range-slider v-model="dateRange" :max="dateMax" :min="dateMin" hide-details class="align-center" color="accent">
                <template v-slot:prepend>
                  <v-text-field filled rounded dense :value="dateRange[0]" disabled
                                class="mt-0 pt-0" hide-details single-line style="width: 90px" color="accent"> </v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field filled rounded dense :value="dateRange[1]" disabled
                                class="mt-0 pt-0" hide-details single-line style="width: 90px" color="accent"></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="accent" @click="voidFilters()">{{L('Void')}}</v-btn>
          <v-btn text color="accent" @click="filterDialog = false">{{L('Ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



  </v-container>
</template>

<script>
import utils from '../utils'
import log from '../log'
import { mapActions, mapGetters } from 'vuex'
import goTo from 'vuetify/es5/services/goto'
import config from "@/config";
export default {
  name: 'Artworks',
  data() {
    return {
      dateMin : -450,
      dateMax : 2020,
      dateRange : [-450, 2020],
      searchTimeout : 0,
      infiniteId : new Date(),
      query : {
        title : null,
        artist : null,
        collection : null,
        technique : null,
        from : null,
        to : null
      },
      count : 0,
      items : [],
      pagination: {
        page : 1,
        limit : 20
      },
    }
  },
  watch: {
    dateRange() {
      const from = this.dateRange[0];
      const to = this.dateRange[1];
      this.query.from = from > this.dateMin ? from : null;
      this.query.to = to < this.dateMax ? to : null;
    },
    searchTerm() {
      if(this.searchTerm && this.searchTerm.length >= 3) {
        this.query.title = this.searchTerm;
      } else if(this.query.title) {
        this.query.title = null;
      }
    },
    query: {
      deep: true,
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.applyFilter, 500);
      }
    },
    mainCollection: {
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.applyFilter, 500);
      }
    }
  },
  computed : {
    ...mapGetters(['artists','techniques','language','mainCollection','userLicenses','languageMap']),
    compareItems : {
      get() { return this.$store.state.compareItems; },
      set(newVal) { this.$store.state.compareItems = newVal }
    },
    artistOpts() {
      const lng = this.language;
      return this.artists.map((item)=>{
        let text = item.name[lng] ? item.name[lng] : item.name.it ? item.name.it : item.name.en ? item.name.en : '-';
        return { text, value : item._id }
      })
    },
    techniqueOpts() {
      const lng = this.language;
      return this.techniques.map((item)=>{
        let text = item.name[lng] ? item.name[lng] : item.name.it ? item.name.it : item.name.en ? item.name.en : '-';
        return { text, value : item._id }
      })
    },
    searchTerm : {
      get() { return this.$store.state.searchTerm; },
      set(newVal) { this.$store.state.searchTerm = newVal; }
    },
    filterDialog : {
      get() { return this.$store.state.filterDialog; },
      set(newVal) { this.$store.state.filterDialog = newVal; }
    },
  },
  methods: {
    ...mapActions(['load','sendError','refreshCollection','loadUserLicenses','compare']),
    L( key ) {
      return this.languageMap[key] || key;
    },
    inCompare( id ) {
      for(let i = 0; i < this.compareItems.length; i++) {
        if(this.compareItems[i]._id === id) return true
      }
      return false;
    },
    getTitle(item) {
      const lng = this.language;
      return item.title[lng] ? item.title[lng] : item.title.it ? item.title.it : item.title.en ? item.title.en : '<no translation>';
    },
    voidFilters() {
      this.query = {
        title : null,
        artist : null,
        technique : null,
        collection : null,
        from : null,
        to : null
      }
      this.dateRange = [-450, 2020]
      this.searchTerm = null;
    },
    getArtists(item) {
      if(!item.artists_names || !item.artists_names.length) {
        return this.L('unknown artist');
      }
      const lng = this.language
      return item.artists_names.map((names) => {
        return names[lng] ? names[lng] : names.it ? names.it : names.en ? names.en : '<no translation>'
      }).join(", ")
    },
    getArtistName( id ) {
      const lng = this.language
      const filt = this.artists.filter((item) => item._id === id)
      if(!filt.length) return '-'
      const artist = filt[0];
      return artist.name && artist.name[lng] ? artist.name[lng] : artist.name.it ? artist.name.it : artist.name.en ? artist.name.en : '<no artist translation>'
    },
    getTechniqueName( id ) {
      const lng = this.language
      const filter = this.techniques.filter((item) => item._id === id);
      log.e("filter", filter)
      const tech = filter[0];
      return tech.name[lng] ? tech.name[lng] : tech.name.it ? tech.name.it : tech.name.en ? tech.name.en : '<no technique translation>'
    },
    infiniteHandler($state) {
      this.loadPage($state)
    },
    addSkeletons(length) {
      this.items.push(...Array.from({length}, (v, i) => {
        return { _id : 'sk_' + i, isSkeleton : true };
      }));
    },
    removeSkeletons() {
      this.items = this.items.filter((item) => !item.isSkeleton);
    },
    applyFilter() {
      this.items = [];
      this.pagination.page = 1;
      this.infiniteId = new Date();
      goTo(0);
    },
    loadPage($state) {
      const { page, limit } = this.pagination;
      let query = { lng : this.language };
      if(this.query.title && this.query.title.length >= 3) {
        query.q = this.query.title;
      }
      if(config.artist) query.artist = config.artist;
      if(this.query.technique) query.technique = this.query.technique;
      if(this.query.from !== null && this.query.from.toString()) query.from = this.query.from.toString();
      if(this.query.to !== null && this.query.to.toString()) query.to = this.query.to.toString();
      if(this.mainCollection) query.collection = this.mainCollection;
      const sort = `-priority,+title.${this.language}`;


      let filter = { query, limit, page, sort };
      this.addSkeletons(limit);
      log.d("query", filter)

      let collection = query.q ? 'search/artworks' : 'artworks'

      this.load({ collection, filter}).then((reply)=>{
        this.removeSkeletons();
        if(reply.success) {
          if(!reply.data || !reply.data.length) {
            $state.complete();
            return
          }
          this.count = reply.count;
          this.items.push(...reply.data);
          this.pagination.page++;
          $state.loaded();
          return;
        }
        $state.error();
      }).catch((err)=>{
        if(!utils.checkError(this, err)) return;
        this.sendError({message:this.L('Sorry there was a server error, please try again later')});
        log.w("error loading artworks", err);
        $state.error();
      })
    },
  },
  mounted() {
    this.searchTerm = null;
  }
}
</script>
