import log from "../log";
import moment from "moment";


export default {
    ratingDialog : (state, { value }) => {
        state.ratingDialog = value;
    },
    voted : (state, { value }) => {
        state.voted = value;
        localStorage.voted = true
    },
    numVotes : (state, { value }) => {
        state.numVotes = value;
    },
    average : (state, { value }) => {
        state.average = value;
    },
    initialized : (state, { value }) => {
        state.initialized = value;
    },
    token : (state, { token }) => {
        log.d("setting token in mutation", token)
        state.token = token;
        if(token)
            sessionStorage.setItem("token" , token);
        else
            sessionStorage.removeItem("token");
    },
    collection : (state, { data }) => {
        state.collection = data;
    },
    profile : (state, { profile }) => {
        state.profile = profile;
    },
    message : (state, { type, message }) => {
        let langMap = state.localizations[state.language] || state.localizations.en || state.localizations.it || {}
        message = langMap[message] ? langMap[message] : message;
        state.snackbarColor = type
        state.dialogMessage = message;
        state.snackbar = true;
    },
    confirm : (state, { title, message, callback }) => {
        state.confirmDialogTitle = title || 'Richiesta conferma';
        state.confirmDialogMessage = message || 'Sei sicuro?';
        state.confirmDialogCallback = callback || null;
        state.confirmDialog = true;
    },
    language : (state, { language }) => {
        localStorage.language = language
        state.language = language;
        moment.locale(language)
    },
    languages : (state, { data }) => {
        state.languages = data ? data : [];
    },
    artworks : (state, { data }) => {
        state.artworks = data ? data : [];
    },
    artists : (state, { data }) => {
        state.artists = data ? data : [];
    },
    techniques : (state, { data }) => {
        state.techniques = data ? data : [];
    },
    categories : (state, { data }) => {
        state.categories = data ? data : [];
    },
    hotspots : (state, { data }) => {
        state.hotspots = data ? data : [];
    },
    pendingHotspot : (state, { hotspot }) => {
        state.pendingHotspot = hotspot;
    },
    pendingHotspotRight : (state, { hotspot }) => {
        state.pendingHotspotRight = hotspot;
    },
    timeline : (state, { data }) => {
        state.timeline = data;
    },
    timelineTree : (state, { data }) => {
        state.timelineTree = data;
    },
    userLicenses : (state, { licenses }) => {
        state.userLicenses = licenses || [];
    },
    localizations : (state, { data }) => {
        state.localizations = data;
    },
    collections : (state, { data }) => {
        state.collections = data;
    },
};