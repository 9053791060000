<template>
  <v-container fluid class="fill-height gradient-bg">
    <div class="d-flex justify-center align-center fill-height full-width ">
      <v-row style="max-width: 600px">
        <v-col cols="12" class="text-center">
          <a href="/"><v-img class="d-inline-flex" src="../assets/logo_xxh_noborder.png" style="width: 100%; height: auto" contain max-width="360px" /></a>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-icon size="240" color="accent">mdi-emoticon-dead-outline</v-icon>
          <h1 class="display-3">{{L('Oh no!')}}</h1>
          <h2 class="display-1">{{L("Sorry! The page you requested does not exists")}}</h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import utils from '../utils'
import log from '../log'
export default {
  name: "NotFound",
  data() {
    return {}
  },
  computed : {
    ...mapGetters(['languageMap']),
  },
  methods : {
    L( key ) {
      return this.languageMap[key] || key;
    },
  }
}
</script>
