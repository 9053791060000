
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import InfiniteLoading from 'vue-infinite-loading';
import VueDragscroll from 'vue-dragscroll'
import fullscreen from 'vue-fullscreen'
import '@mdi/font/css/materialdesignicons.css'
import 'vis-timeline/dist/vis-timeline-graph2d.min.css'
import './styles/main.css'


Vue.config.productionTip = false
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(VueDragscroll);
Vue.use(fullscreen)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    // Prevent blank screen in Electron builds
  }
}).$mount('#app')