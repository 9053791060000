import Vue from 'vue'
import VueRouter from 'vue-router'
import Artworks from "../pages/Artworks";
import Artist from "../pages/Artist";
import Artwork from "../pages/Artwork";
import Timeline from "../pages/Timeline";
import Compare from "../pages/Compare";
import NotFound from "../pages/NotFound";
import Home from "@/pages/Home";
Vue.use(VueRouter)

const routes = [
  {
    path: '/artworks',
    name: 'artworks',
    component: Artworks
  },
  {
    path: '/artworks/:id',
    name: 'artwork',
    component: Artwork
  },
  {
    path: '/artist',
    name: 'artist',
    component: Artist
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: Timeline
  },
  {
    path: '/compare/:id',
    name: 'compare',
    component: Compare
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFound
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '*',
    component: NotFound
  }
  /*
    {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../pages/About.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})


export default router
