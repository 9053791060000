<template>
  <div class="full-width fill-height">
    <v-img src="../assets/bg_home.png"></v-img>

    <div class="absolute full-width d-flex justify-space-around" style="top: 0">
      <div style="width: 18%; margin-top: 10%" class="cursor-pointer" @click="$router.push('/artist')">
        <v-img src="../assets/home_1.png" class="flex-grow-0" ></v-img>
        <h1 :class="`home-btn-title ${$vuetify.breakpoint.name}`">{{L('Biography')}}</h1>
        <h3 :class="`home-btn-subtitle ${$vuetify.breakpoint.name}`">{{L('Mantegna')}}</h3>
      </div>
      <div style="width: 18%; margin-top: 10%" class="cursor-pointer" @click="$router.push('/artworks')">
        <v-img src="../assets/home_2.png" class="flex-grow-0" ></v-img>
        <h1 :class="`home-btn-title ${$vuetify.breakpoint.name}`">{{L('Artworks')}}</h1>
        <h3 :class="`home-btn-subtitle ${$vuetify.breakpoint.name}`">{{L('HiRes')}}</h3>
      </div>
      <div style="width: 18%; margin-top: 10%" class="cursor-pointer" @click="$router.push('/timeline')">
        <v-img src="../assets/home_3.png" class="flex-grow-0" ></v-img>
        <h1 :class="`home-btn-title ${$vuetify.breakpoint.name}`">{{L('Historical context')}}</h1>
        <h3 :class="`home-btn-subtitle ${$vuetify.breakpoint.name}`">{{L('Artworks over time')}}</h3>
      </div>


    </div>

    <div class="secondary d-flex flex-column justify-center align-center pa-12 full-width">
      <p :class="`quote-author white--text ${$vuetify.breakpoint.name}`">G. Vasari, Le vite, 1568.</p>
      <p :class="`quote white--text ${$vuetify.breakpoint.name}`">{{L('vasari_text')}}</p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Home",
  computed : {
    ...mapGetters(['languageMap'])
  },
  methods : {
    L( key ) {
      return this.languageMap[key] || key;
    },
  },
}
</script>

<style scoped>

</style>