import {ControlAnchor} from './Control'
import {EventDispatcher, Point, Rect} from '../utils'
import {InteractionManager} from '../ui'
import utils from '../utils'

class Navigator extends EventDispatcher{
    /**
     * @member {XLViewer}
     */
    viewer;
    /**
     * @member {Point}
     */
    viewerSize;
    /**
     * @member {Point}
     */
    oldViewerSize;
    /**
     * @member {Point}
     */
    navigatorSize;
    elementArea;
    /**
     * @member {HTMLImageElement}
     */
    thumb;
    tabIndex = -1;
    controlOptions = {
        anchor:           ControlAnchor.TOP_RIGHT,
        attachToViewer:   true,
        autoFade:         true
    };
    fixedWidth;
    fixedHeight;
    sizeRatio = 0.1;
    borderWidth;
    borderColor;
    focusRegionCont;
    focusRegion;
    focusStroke;
    focusColor;
    aspectRatio;
    naviAspectRatio;
    /**
     * @member {TileSource}
     */
    source;
    baseURI;

    constructor(options) {
        super();
        this.viewer = options.viewer;

        const baseURI = 'xlv-navi-' + Date.now().toString();
        this.baseURI = baseURI;
        if( !options.id ){
            this.element = utils.makeNeutralElement( "div" );
            this.element.id = baseURI;
            this.element.style.position = 'relative';
            if( options.position ){
                if( 'BOTTOM_RIGHT' === options.position ){
                    this.controlOptions.anchor = ControlAnchor.BOTTOM_RIGHT;
                } else if( 'BOTTOM_LEFT' === options.position ){
                    this.controlOptions.anchor = ControlAnchor.BOTTOM_LEFT;
                } else if( 'TOP_RIGHT' === options.position ){
                    this.controlOptions.anchor = ControlAnchor.TOP_RIGHT;
                } else if( 'TOP_LEFT' === options.position ){
                    this.controlOptions.anchor = ControlAnchor.TOP_LEFT;
                } else if( 'ABSOLUTE' === options.position ){
                    this.controlOptions.anchor = ControlAnchor.ABSOLUTE;
                    this.controlOptions.top = options.top;
                    this.controlOptions.left = options.left;
                    this.controlOptions.height = options.height;
                    this.controlOptions.width = options.width;
                }
            }

        } else {
            this.element = document.getElementById( options.id );
            this.controlOptions  = {
                anchor:           ControlAnchor.NONE,
                attachToViewer:   false,
                autoFade:         false
            };
        }
        this.element.classList.add('xlv-navigator');

        utils.setElementTouchActionNone( this.element );

        this.borderWidth = options.borderWidth;
        this.borderColor = options.borderColor;
        this.focusStroke = options.focusStroke;
        this.focusColor = options.focusColor;

        if ( this.controlOptions.anchor !== ControlAnchor.NONE ) {
            utils.applyStyle(this.element,{
                margin : '0px',
                border : this.borderWidth + 'px solid ' + this.borderColor,
                padding : '0px',
                background : '#000',
                opacity : 0.8,
                overflow : 'hidden',
                boxSizing : 'content-box'
            });

        }

        this.focusRegion = utils.makeNeutralElement( "div" );
        this.focusRegion.id        = baseURI + '-navfocus';
        this.focusRegion.className = 'bounds';

        utils.applyStyle(this.focusRegion, {
            position      : 'absolute',
            top           : '0px',
            left          : '0px',
            fontSize      : '0px',
            overflow      : 'hidden',
            border        : this.focusStroke + 'px solid ' + this.focusColor,
            margin        : '0px',
            padding       : '0px',
            background    : 'transparent',
            float         : 'left',
            zIndex        : 1000,
            cursor        : 'default'
        });

        this.focusRegionCont = utils.makeNeutralElement("div");
        this.focusRegionCont.id = baseURI + '-navfocus-cont';
        this.focusRegionCont.className = "navfocus-cont";
        this.focusRegionCont.style.width = "100%";
        this.focusRegionCont.style.height = "100%";
        this.focusRegionCont.style.position = "absolute";
        this.focusRegionCont.style.top = "0";
        this.focusRegionCont.style.left = "0";
        this.focusRegionCont.style.boxSizing = "content-box";

        this.viewer.addControl(
            this.element,
            this.controlOptions
        );

        this.focusRegionCont.appendChild(this.focusRegion);
        this.element.appendChild(this.focusRegionCont);
        this.fixedWidth = options.width;
        this.fixedHeight = options.height;
        if(options.sizeRatio) {
            this.sizeRatio = options.sizeRatio;
        }

        this.thumb = new Image();
        this.thumb.id = this.baseURI+"-img";
        utils.applyStyle(this.thumb, {
            display: 'block',
            'object-fit' : 'contain'
        })

        this.updateSize()



        this.element.insertBefore(this.thumb, this.element.children[0]);

        let rotate = (rotation) => {
            this.setTransformRotate(this.focusRegionCont, rotation);
            this.setTransformRotate(this.focusRegion, -rotation);
            this.setTransformRotate(this.thumb);
        }
        if (options.rotate) {
            let rotation = this.viewer.viewport ?
                this.viewer.viewport.getRotation() :
                this.viewer.rotation || 0;
            rotate(rotation);
            options.viewer.addListener("rotate", (args) => {
                rotate(args.rotation);
            });
        }



        // Remove the base class' (Viewer's) innerTracker and replace it with our own
        if(this.innerTracker) this.innerTracker.destroy();
        this.innerTracker = new InteractionManager({
            element:         this.element,
            dragHandler:     (e) => this.onDrag(e),
            clickHandler:    (e) => this.onClick(e),
            releaseHandler:  (e) => this.onRelease(e)
        });

    }



    /**
     * Used to notify the navigator when its size has changed.
     * Especially useful when navigator autoResize is set to false and the navigator is resizable.
     */
    updateSize () {
        if ( this.controlOptions.anchor !== ControlAnchor.NONE ) {
            this.viewerSize = this.viewer.getSize();
            if(this.oldViewerSize && this.oldViewerSize.equals(this.viewerSize)) return;

            if(this.fixedWidth) {
                this.navigatorSize = new Point(this.fixedWidth, Math.round(this.fixedWidth / this.viewerSize.x *  this.viewerSize.y));
                this.sizeRatio = this.fixedWidth / this.viewerSize.x
            } else if(this.fixedHeight) {
                this.navigatorSize = new Point(Math.round(this.fixedHeight / this.viewerSize.y * this.viewerSize.x), this.fixedHeight);
                this.sizeRatio = this.fixedHeight / this.viewerSize.y
            } else {
                this.navigatorSize = this.viewerSize.times(this.sizeRatio);
            }

            this.naviAspectRatio = this.navigatorSize.x / this.navigatorSize.y;

            utils.applyStyle(this.element, {
                width : `${this.navigatorSize.x}px`,
                height : `${this.navigatorSize.y}px`
            });

            this.thumb.width = this.navigatorSize.x;
            this.thumb.height = this.navigatorSize.y;
            this.oldViewerSize = this.viewerSize
        }
    }

    /**
     * Used to update the navigator minimap's viewport rectangle when a change in the viewer's viewport occurs.
     */
    update( viewport ) {

        this.updateSize();
        if(viewport) {
            let bounds = viewport.getBounds( true );
            let thumbBounds = this.getNaviImageBounds(viewport);
            let topLeft = this.viewportToNavi(new Point(bounds.x, bounds.y), thumbBounds)

            let w = Math.min(Math.floor(thumbBounds.width * bounds.width ), this.navigatorSize.x);
            let h =  Math.min(Math.floor(thumbBounds.height * bounds.height), this.navigatorSize.y);
            //update style for navigator-box
            utils.applyStyle(this.focusRegion, {
                display: 'block',
                boxSizing : 'border-box',
                top : `${Math.max(Math.floor(topLeft.y), 0)}px`,
                left : `${Math.max(Math.floor(topLeft.x), 0)}px`,
                width: `${w}px`,
                height: `${h}px`,
            })

        }

    }

    /**
     * Ritorna il rettangolo dei bounds normalizzato alle dimensioni che vanno da 0 a 1
     * @returns {Rect}
     */
    getNaviImageBounds( viewport ) {
        let bounds = viewport.getHomeBounds()
        let w = Math.round(this.navigatorSize.x / bounds.width);
        let h = Math.round(this.navigatorSize.y / bounds.height);
        let x = -Math.round(w * bounds.x);
        let y = -Math.round(h * bounds.y);
        return new Rect(x,y,w,h);
    }
    /**
     * data un un punto con coordinate (0 - 1) ritorna la stesso punto in pixel rispetto al navigatore
     * @param coord {Point}
     * @param imageBounds {Rect}
     * @returns {Point}
     */
    viewportToNavi(coord , imageBounds) {
        // prendo le misure reali dell'immagine alla view corrente
        if(!imageBounds)
            return null;
        let x = Math.round(coord.x * imageBounds.width + imageBounds.x);
        let y = Math.round(coord.y * imageBounds.height + imageBounds.y);
        return new Point(x, y);
    }

    /**
     * Convert pixel coordinates to viewport coordinates.
     * This method does not take rotation into account.
     * Consider using pointFromPixel if you need to account for rotation.
     * @param {Point} pixel Pixel coordinates
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    pointFromPixelNoRotate (pixel, current) {
        let bounds = this.viewer.viewport.getHomeBounds()
        return pixel.divide(
            this.navigatorSize.x / bounds.width
        ).plus(
            bounds.getTopLeft()
        );
    }

    /**
     * Convert pixel coordinates to viewport coordinates.
     * @param {Point} pixel Pixel coordinates
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    pointFromPixel (pixel) {
        return this.pointFromPixelNoRotate(pixel, true).rotate(
            -this.viewer.viewport.getRotation(),
            this.viewer.viewport.getCenter(true)
        );
    }

    /**
     * Convert a delta (translation vector) from pixels coordinates to viewport
     * coordinates. This method does not take rotation into account.
     * Consider using deltaPointsFromPixels if you need to account for rotation.
     * @param {Point} deltaPixels - The translation vector to convert.
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    deltaPointsFromPixelsNoRotate (deltaPixels, current) {
        return deltaPixels.divide(
            this.navigatorSize.x / this.viewer.viewport.getHomeBounds().width
        );
    }

    /**
     * Convert a delta (translation vector) from pixels coordinates to viewport
     * coordinates.
     * @param {Point} deltaPixels - The translation vector to convert.
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    deltaPointsFromPixels (deltaPixels, current) {
        return this.deltaPointsFromPixelsNoRotate(deltaPixels, current)
            .rotate(-this.viewer.viewport.getRotation());
    }


    loadImage() {
        this.source = this.viewer.manager.getItemAt(0).source;
        this.thumb.src = `${this.source.baseUrl}?cmd=thumb&q=${this.viewer.quality}`
        //this.thumb.src = this.source.baseUrl + '?cmd=thumb&q='+this.viewer.quality;
    }


    onClick( event ) {
        if ( event.quick ) {
            this.viewer.viewport.panTo(this.pointFromPixel(event.position));
            this.viewer.viewport.applyConstraints();
        }
    }

    onDrag( event ) {
        if ( this.viewer.viewport ) {
            if( this.blockHorizontalPanning ){
                event.delta.x = 0;
            }
            if( this.blockVerticalPanning ){
                event.delta.y = 0;
            }
            this.viewer.viewport.panBy(
                this.deltaPointsFromPixels(
                    event.delta, true
                )
            );
        }
    }

    onRelease( event ) {
        if ( event.insideElementPressed && this.viewer.viewport ) {
            this.viewer.viewport.applyConstraints();
        }
    }


    setTransformRotate (element, rotation) {
        element.style.webkitTransform = "rotate(" + rotation + "deg)";
        element.style.mozTransform = "rotate(" + rotation + "deg)";
        element.style.msTransform = "rotate(" + rotation + "deg)";
        element.style.oTransform = "rotate(" + rotation + "deg)";
        element.style.transform = "rotate(" + rotation + "deg)";
    }

    //TODO
    destroy() {
    }
}


export default Navigator;