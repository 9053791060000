<template>
  <v-app class="fill-height">
    <v-navigation-drawer v-model="drawer" app clipped width="180" class="secondary" style="z-index: 11">

      <div class="d-flex flex-column fill-height">
        <v-select class="flex-grow-0 ml-2 mr-2 mt-3"  color="accent" light v-if="$vuetify.breakpoint.mdAndDown"
                  :items="languageOptions" solo rounded hide-details :label="L('Language')" v-model="language">
          <template v-slot:selection="data">
            <span class="body-2">{{ data.item.text }}</span>
          </template>

          <template v-slot:item="{item, on, attrs}">
            <span class="body-2">{{ item.text }}</span>
          </template>
        </v-select>

        <v-list dense :key="refreshMenu" class="flex-grow-1">
          <v-list-item link v-for="page in pages" :key="page.link" :to="page.link" router active-class="accent--text">
            <v-list-item-action class="mr-3"><v-icon color="accent">{{page.icon}}</v-icon></v-list-item-action>
            <v-list-item-content><v-list-item-title>{{page.title}}</v-list-item-title></v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

    </v-navigation-drawer>


    <v-app-bar app clipped-left v-if="toolbarEnabled" class="gradient-bg" height="90">

      <div class="full-width d-flex justify-center align-end flex-grow-1 flex-shrink-0">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="absolute" :style="navIconStyle">
          <v-img src="./assets/hamburger.png" />
        </v-app-bar-nav-icon>

        <div class="d-flex flex-column flex-lg-row justify-center align-center">
          <img :style="logoStyle" src="./assets/logo_mantegna.png"  class="flex-grow-0 mb-2 mb-lg-0" />
          <p class="mb-0 ml-lg-2 mr-lg-2 mt-lg-0 mb-lg-0 mt-2 mb-2 d-none d-lg-block">-</p>
          <img :style="logoArtStyle" src="./assets/logo_xxh_noborder.png" class="flex-grow-0 flex-shrink-1 mt-2 mt-lg-0" />
        </div>



      </div>

      <v-select v-if="$vuetify.breakpoint.lgAndUp" class="absolute flex-shrink-1 flex-grow-0" :style="barLangStyle" color="accent" light
                :items="languageOptions" dense solo rounded hide-details :label="L('Language')" v-model="language">
        <template v-slot:selection="data">
          <span class="body-2">{{ data.item.text }}</span>
        </template>

        <template v-slot:item="{item, on, attrs}">
          <span class="body-2">{{ item.text }}</span>
        </template>
      </v-select>



      <v-btn :text="numVotes > 0" class="absolute"
             :icon="!numVotes" :style="starIconStyle" @click="ratingDialog = true"><v-icon>mdi-star-outline</v-icon><span v-if="numVotes > 0">{{average.toFixed(2)}}</span></v-btn>
    </v-app-bar>

    <v-img v-if="hasBg" :src="collectionBg" class="main-bg" ></v-img>
    <v-main>

      <router-view id="main" v-if="initialized" :key="$route.path"></router-view>
      <loading v-else></loading>

      <v-dialog v-model="confirmDialog" persistent max-width="400" >
        <v-card>
          <v-card-title class="headline">{{confirmDialogTitle}}</v-card-title>
          <v-card-text>
            <p class="body-1">{{confirmDialogMessage}}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text rounded @click="confirmDialogCallback(false); confirmDialog = false">{{L('No')}}</v-btn>
            <v-btn color="accent" rounded @click="confirmDialogCallback(true); confirmDialog = false">{{L('Yes')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-snackbar app :timeout="5000" :color="snackbarColor" top multi-line v-model="snackbar">
        {{ dialogMessage }}
      </v-snackbar>

      <v-dialog v-model="compareDialog" max-width="800" >
        <v-card class="gradient-bg">

          <v-card-title class="justify-center text-center">
            <h2 class="title">{{L('Compare artworks')}}</h2>
          </v-card-title>
          <v-card-text class="pb-0">

            <v-row>
              <v-col cols="12" lg="6" v-for="item in compareItems" :key="'compare_'+item._id">
                <v-card @click="$router.push('/artworks/' + item._id)" class="secondary">
                  <v-responsive :aspect-ratio="4/3">
                    <v-img :aspect-ratio="16/9" :src="getCompareThumb(item)" contain style="background: black" >
                    </v-img>
                    <v-card-title class="subtitle-1 text-center justify-center">
                      {{getTitle(item)}}
                    </v-card-title>
                    <v-card-subtitle class="subtitle-1 info--text text-center justify-center">
                      {{getArtists(item)}}
                    </v-card-subtitle>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer />
            <v-btn class="stop-uppercase title" rounded color="#253835" @click="compareItems = []; compareDialog = false;">{{L('Cancel')}}</v-btn>
            <v-btn class="stop-uppercase title" rounded color="accent" @click="goToCompare()">{{L('Compare')}}</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="ratingDialog" persistent max-width="400" >
        <v-card>
          <v-card-text class="d-flex flex-column align-center justify-center">
            <p class="text-body-1 mb-2 font-weight-regular mt-6" v-if="!voted">{{L('How much do you like this experience?')}}</p>
            <p class="text-body-1 mb-2 font-weight-regular mt-6" v-else>{{L('Your vote has already been collected')}}</p>
            <v-rating v-model="rating" color="primary" v-if="!voted"></v-rating>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="ratingDialog = false">{{L('Cancel')}}</v-btn>
            <v-btn color="primary" :disabled="rating === null" text @click="sendVote()">{{L('Send vote')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-main>
  </v-app>
</template>

<script>
import log from './log'
import { mapActions, mapGetters, mapState } from 'vuex'
import utils from "./utils";
import Loading from "./components/Loading";

export default {
  components: {
    Loading,
  },
  name: 'App',
  data: () => ({
    loading : false,
    refreshMenu : 9000000,
    refreshInterval : -1,
    rating : null,
  }),
  computed : {
    ...mapGetters(['profile','languages','languageMap', 'collection','userLicenses','initialized','voted',"numVotes", "average"]),
    ...mapState(['confirmDialogTitle','confirmDialogMessage','confirmDialogCallback', 'dialogMessage', 'snackbarColor']),
    ratingDialog : {
      get() {
        return this.$store.state.ratingDialog;
      },
      set(value) {
        this.$store.commit('ratingDialog', { value })
      }
    },
    hasBg() {
      return this.toolbarEnabled && !!this.collectionBg
    },
    collectionBg() {
      return this.collection ? this.collection.background || null : null;
    },
    logoStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 'height: 18px; width: auto;';
        case "sm":
        case "md":
          return 'height: 24px; width: auto;';
        case "lg":
          return 'height: 28px; width: auto;';
        case "xl":
          return 'height: 39px; width: auto;';
      }
    },
    logoArtStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 'height: 14px; width: auto;';
        case "sm":
        case "md":
          return 'height: 16px; width: auto;';
        case "lg":
          return 'height: 18px; width: auto;';
        case "xl":
          return 'height: 23px; width: auto;';
      }
    },
    navIconStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 'left: 16px';
        case "sm":
        case "md":
          return 'left: 32px';
        case "lg":
          return 'left: 32px';
        case "xl":
          return 'left: 80px';
      }
    },
    barLangStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 'right: 112px; width: 168px';
        case "xl":
          default:
          return 'right: 162px; width: 188px';
      }
    },
    starIconStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 'right: 16px';
        case "sm":
        case "md":
          return 'right: 32px';
        case "lg":
          return 'right: 32px';
        case "xl":
          return 'right: 80px';
      }
    },
    languageOptions() {
      return [
        { value : 'it', text : 'Italiano' },
        { value : 'en', text : 'English' }
      ]
      /*
      this.languages.map((item)=>{
        return { value : item.code, text : item.lang };
      })
       */
    },
    pages() {
      //non rimuovere forza il refresh, è una merda ma è l'unico modo per farla funzionare
      const language = this.language;
      const i = this.refreshMenu;

      return  [
        { title : this.L("Home"), icon : "mdi-home", link : "/" },
        { title : this.L("Artworks"), icon : "mdi-postage-stamp", link : "/artworks" },
        { title : this.L("Mantegna"), icon : "mdi-brush", link : "/artist" },
        { title : this.L("Historical Context"), icon : "mdi-timeline-clock", link : "/timeline" }
      ];
    },
    toolbarEnabled() {
      return this.$route.name !== "artwork" && this.$route.name !== "compare"
    },
    language : {
      get() { return this.$store.state.language; },
      set(language) {
        if(['it','en','de'].includes(language)) {
          this.$vuetify.lang.current = language;
        } else if(language === 'zh') {
          this.$vuetify.lang.current = 'zhHans'
        } else {
          this.$vuetify.lang.current = 'en'
        }

        this.setLanguage({language})
      }
    },
    compareDialog : {
      get() { return this.$store.state.compareDialog; },
      set(newVal) { this.$store.state.compareDialog = newVal }
    },
    compareItems : {
      get() { return this.$store.state.compareItems; },
      set(newVal) { this.$store.state.compareItems = newVal }
    },
    drawer : {
      get() { return this.$store.state.drawer; },
      set(newVal) { this.$store.state.drawer = newVal; }
    },
    searchTerm : {
      get() { return this.$store.state.searchTerm; },
      set(newVal) { this.$store.state.searchTerm = newVal; }
    },
    filterDialog : {
      get() { return this.$store.state.filterDialog; },
      set(newVal) { this.$store.state.filterDialog = newVal; }
    },
    snackbar : {
      get() { return this.$store.state.snackbar; },
      set(newVal) { this.$store.state.snackbar = newVal; }
    },
    confirmDialog : {
      get() { return this.$store.state.confirmDialog; },
      set(newVal) { this.$store.state.confirmDialog = newVal; }
    },
  },
  methods : {
    ...mapActions(['login','setLanguage','refreshLocalizations','loadUserLicenses','setPendingHotspot','setPendingHotspotRight',
      'refreshCollection','refreshToken','setInitialized','loadCollection','sendRating','getRating']),
    L( key ) {
      return this.languageMap[key] ? this.languageMap[key] : key;
    },
    async sendVote() {
      if(!this.voted) {
        await this.sendRating({vote:this.rating})
        this.$store.commit('voted', {value:true})
        await this.getRating();
      }
      this.ratingDialog = false
    },
    getTitle(item) {
      const lng = this.language;
      return item.title[lng] ? item.title[lng] : item.title.it ? item.title.it : item.title.en ? item.title.en : '<no translation>';
    },
    getArtists(item) {
      if(!item.artists_names || !item.artists_names.length) {
        return this.L('unknown artist');
      }
      const lng = this.language
      return item.artists_names.map((names) => {
        return names[lng] ? names[lng] : names.it ? names.it : names.en ? names.en : '<no translation>'
      }).join(", ")
    },

    getCompareThumb(item) {
      log.d('getCompareThumb', item);
      if(!item.hotspot) return item.imgfUrl + '?cmd=thumb';
      return utils.xlimageThumbHotspotURL(item.hotspot.imgfUrl, item.hotspot, 256, 256, 90)
    },
    goToCompare() {
      let left = true;
      let id = this.compareItems.map((item)=>{
        if(item.hotspot) {
          if(left)
            this.setPendingHotspot({ hotspot : item.hotspot });
          else
            this.setPendingHotspotRight({ hotspot : item.hotspot });
        }
        left = false;
        return item._id
      }).join('-');

      this.compareItems= [];
      this.compareDialog = false;

      this.$router.push('/compare/'+id);
    }
  },
  created () {
    this.$vuetify.theme.dark = true
  },
  async mounted() {
    await this.login()
    await this.loadUserLicenses();
    await this.refreshLocalizations();
    await this.loadCollection();
    this.getRating();
    this.refreshCollection({collection: 'languages'});
    this.refreshCollection({collection: 'artists'});
    this.refreshCollection({collection: 'techniques'});
    this.refreshCollection({collection: 'collections'});

    this.setInitialized()

    this.refreshInterval = setInterval(this.refreshToken, 60000);
  }
};
</script>
